import {
  Button,
  Card,
  DatePicker,
  Form,
  Input,
  PageHeader,
  Upload,
} from "antd";
import dayjs from "dayjs";
import { useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";

import Preloader from "@/components/Preloader";
import { ShowErrorMessages, errorActions } from "@/entities/error";
import * as form from "@/constants/formsWrappers";
import { slugRegExp } from "@/constants/params";
import { useAppDispatch, useAppSelector } from "@/app";
import {
  convertIFileResponseToPhotoListItem,
  createInitUploadConfig,
} from "@/shared/models/files";
import customUpload, { normFile } from "@/shared/utils/customUpload";
import { IOfferEditValues, editOffer, fetchOffer } from "@/entities/offers";
import { IOfferEditArgs } from "@/entities/offers/model/types/offers";

import PageNotFound from "./PageNotFound";

function OfferEdit() {
  const param = useParams();
  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  useEffect(() => {
    void dispatch(errorActions.resetErrors());
    param.id && dispatch(fetchOffer(param.id));
  }, []);

  const offerData = useAppSelector(({ offers }) => offers);
  const { loading, offerDetail } = offerData;

  const initFile = createInitUploadConfig(offerDetail?.photo);

  const handleOfferEdit = (values: IOfferEditValues) => {
    console.log("values: ", values);

    if (!offerDetail || !values.photo || !values.photo[0]?.response?.code)
      return;
    const request: IOfferEditArgs = {
      code: offerDetail.code,
      name: values.name,
      title: values.title,
      endAt: dayjs(values.endAt).format("YYYY-MM-DD hh:mm:ss"),
      photo: convertIFileResponseToPhotoListItem(values.photo[0].response),
    };
    void dispatch(editOffer(request));
  };

  if (!param.id || loading === "failed") {
    return <PageNotFound />;
  }

  if (loading === "loading") {
    return <Preloader />;
  }

  return offerDetail ? (
    <>
      <PageHeader
        title={`Редактирование Спец-предложения: ${offerDetail.name}`}
        onBack={() => navigate(-1)}
      />
      <Card>
        <ShowErrorMessages />
        {offerDetail && (
          <Form
            name="editOffer"
            labelCol={form.LebelColWide}
            wrapperCol={form.WrapperColWide}
            autoComplete="off"
            initialValues={{
              name: offerDetail.name,
              title: offerDetail.title,
              endAt: dayjs(offerDetail.endAt, "YYYY-MM-DD hh:mm:ss"),
              photo: initFile,
            }}
            onFinish={handleOfferEdit}
          >
            <Form.Item
              label="Метка"
              name="name"
              rules={[
                {
                  required: true,
                  message: "Пожалуйста укажите метку спец-предложения",
                },
                {
                  required: true,
                  pattern: new RegExp(slugRegExp),
                  message: "Пожалуйста метку латинскими буквами",
                },
              ]}
            >
              <Input />
            </Form.Item>

            <Form.Item
              label="Заголовок"
              name="title"
              rules={[
                {
                  required: true,
                  message: "Пожалуйста укажите заголовок спец-предложения",
                },
              ]}
            >
              <Input />
            </Form.Item>

            <Form.Item
              label="Дата до"
              name="endAt"
              rules={[
                {
                  required: true,
                  message: "Пожалуйста укажите дату завершения",
                },
              ]}
            >
              <DatePicker
                showTime={{
                  format: "HH:mm",
                }}
              />
            </Form.Item>

            <Form.Item
              label="Фото"
              name="photo"
              valuePropName="fileList"
              getValueFromEvent={normFile}
              rules={[
                {
                  required: true,
                  message: "Пожалуйста укажите фото спец-предложения",
                },
              ]}
            >
              <Upload
                accept="image/*"
                customRequest={customUpload}
                listType="picture-card"
                multiple={false}
                maxCount={1}
                defaultFileList={initFile}
              >
                <Button type="link">Загрузить</Button>
              </Upload>
            </Form.Item>

            <Form.Item wrapperCol={form.SubmitCol}>
              <Button type="primary" htmlType="submit">
                Сохранить
              </Button>
            </Form.Item>
          </Form>
        )}
      </Card>
    </>
  ) : null;
}

export default OfferEdit;
