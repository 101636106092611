import {
  Button,
  Card,
  Form,
  Input,
  PageHeader,
  Radio,
  Select,
  Upload,
} from "antd";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

import { ShowErrorMessages, errorActions } from "@/entities/error";
import TextEditor from "@/components/TextEditor";
import * as form from "@/constants/formsWrappers";
import { phoneRegExp } from "@/constants/params";
import { useAppDispatch, useAppSelector } from "@/app";
import {
  coachTypes,
  ICoachCreateArgs,
  ICoachsCreateValues,
} from "@/entities/coachs/model/types/coachs";
import { convertIFileResponseToPhotoListItem } from "@/shared/models/files";
import frontendPaths from "@/routes/paths";
import customUpload, { normFile } from "@/shared/utils/customUpload";
import { createCoach } from "@/entities/coachs";
import { clubsSelectors } from "@/entities/club";

const { TextArea } = Input;

function CoachCreate() {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const [isCreated, setIsCreated] = useState(false);

  const clubs = useAppSelector(clubsSelectors.selectAll);
  const clubsOptions = clubs.map((item) => ({
    value: item.code,
    label: item.name,
  }));

  useEffect(() => {
    void dispatch(errorActions.resetErrors());
  }, []);

  const handleCreateCoach = async (values: ICoachsCreateValues) => {
    if (!values.photo || !values.photo[0].response?.code) return;

    const request: ICoachCreateArgs = {
      ...values,
      photo: convertIFileResponseToPhotoListItem(values.photo[0].response),
      clubs: clubs
        .filter((item) => values.clubCodes?.includes(item.code))
        .map((item) => {
          return {
            code: item.code,
            name: item.name,
            contactPhone: item.contactPhone,
          };
        }),
    };
    await dispatch(createCoach(request)).then((res) => {
      if (res.meta.requestStatus === "fulfilled") setIsCreated(true);
    });
  };

  return (
    <>
      <PageHeader
        title={frontendPaths.coach_create.title}
        onBack={() => navigate(-1)}
      />
      <Card>
        <ShowErrorMessages />
        <Form
          name="createCoach"
          labelCol={form.LebelColWide}
          wrapperCol={form.WrapperColWide}
          autoComplete="off"
          onFinish={handleCreateCoach}
          disabled={isCreated}
        >
          <Form.Item
            label="Имя тренера"
            name="firstName"
            rules={[
              {
                required: true,
                message: "Пожалуйста укажите имя тренера",
              },
            ]}
          >
            <Input />
          </Form.Item>

          <Form.Item
            label="Фамилия тренера"
            name="lastName"
            rules={[
              {
                required: true,
                message: "Пожалуйста укажите фамилию тренера",
              },
            ]}
          >
            <Input />
          </Form.Item>

          <Form.Item
            label="Тип тренера"
            name="jobType"
            rules={[
              {
                required: true,
                message: "Пожалуйста укажите тип работы тренера",
              },
            ]}
          >
            <Radio.Group options={coachTypes} />
          </Form.Item>

          <Form.Item
            label="Телефон"
            name="phone"
            rules={[
              {
                required: true,
                message: "Пожалуйста укажите телефон тренера",
              },
              {
                required: true,
                pattern: new RegExp(phoneRegExp),
                message: "Пожалуйста введите корректный телефон",
              },
            ]}
          >
            <Input />
          </Form.Item>

          <Form.Item label="Имя пользователя телеграмм" name="socialTG">
            <Input />
          </Form.Item>

          <Form.Item label="Имя пользователя вконтакте" name="socialVK">
            <Input />
          </Form.Item>

          <Form.Item label="Клубы тренера" name="clubCodes">
            <Select options={clubsOptions} mode="multiple" allowClear />
          </Form.Item>

          <Form.Item
            label="Краткое описание"
            name="shortBio"
            rules={[
              {
                required: true,
                message: "Пожалуйста укажите краткое описание тренера",
              },
            ]}
          >
            <TextArea rows={4} />
          </Form.Item>

          <Form.Item
            label="Описание"
            name="description"
            rules={[
              {
                required: true,
                message: "Пожалуйста укажите описание тренера",
              },
            ]}
          >
            <TextEditor value="description" readonly={isCreated} />
          </Form.Item>

          <Form.Item
            label="Фото тренера"
            name="photo"
            valuePropName="fileList"
            getValueFromEvent={normFile}
            rules={[
              {
                required: true,
                message: "Пожалуйста укажите фото тренера",
              },
            ]}
          >
            <Upload
              accept="image/*"
              customRequest={customUpload}
              listType="picture-card"
              multiple={false}
              maxCount={1}
            >
              <Button type="link">Загрузить</Button>
            </Upload>
          </Form.Item>

          <Form.Item wrapperCol={form.SubmitCol}>
            <Button type="primary" htmlType="submit">
              Сохранить
            </Button>
          </Form.Item>
        </Form>
      </Card>
    </>
  );
}

export default CoachCreate;
