import { Button, Card, PageHeader, Table } from "antd";
import { ColumnProps } from "antd/lib/table";
import { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";

import DeleteConfirmModal from "@/components/modals/DeleteConfirmModal";
import { ShowErrorMessages, errorActions } from "@/entities/error";
import TableActionsMenu from "@/components/TableActionsMenu";
import { useAppDispatch, useAppSelector } from "@/app";
import frontendPaths from "@/routes/paths";
import addReactKey from "@/shared/utils/addReactKey";
import {
  deleteProgramm,
  fetchProgramms,
  IProgramListItem,
  programmsSelectors,
} from "@/entities/programms";

function Programms() {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  const [isProgrammDeleteMode, setProgrammDeleteMode] = useState(false);
  const [removableProgramm, setRemovableProgramm] =
    useState<IProgramListItem | null>(null);

  useEffect(() => {
    void dispatch(errorActions.resetErrors());
    void dispatch(fetchProgramms());
  }, []);

  const programmsData = useAppSelector(({ programms }) => programms);
  const { loading } = programmsData;
  const programms = addReactKey<IProgramListItem>(
    useAppSelector(programmsSelectors.selectAll)
  );

  // delete programm
  const handleTurnOnProgrammDeleteMode = (code: string) => {
    const coachToRemove = programms.find((item) => item.code === code);
    if (coachToRemove) {
      setProgrammDeleteMode(true);
      setRemovableProgramm(coachToRemove);
    }
  };

  const handleDeleteProgrammSubmit = (code: string) => {
    void dispatch(deleteProgramm(code));
    setProgrammDeleteMode(false);
    setRemovableProgramm(null);
  };

  const handleDeleteProgrammCancel = () => {
    setProgrammDeleteMode(false);
    setRemovableProgramm(null);
  };

  const columns: ColumnProps<IProgramListItem>[] = [
    {
      title: "Программа",
      dataIndex: "name",
      key: "name",
      width: "20%",
    },
    {
      title: "Описание",
      dataIndex: "description",
      key: "description",
      width: "50%",
      responsive: ["xxl", "xl", "lg", "md"],
    },
    {
      title: "Продолжительность",
      dataIndex: "duration",
      key: "duration",
      responsive: ["xxl", "xl", "lg", "md"],
    },
    {
      title: "Калории",
      dataIndex: "calories",
      key: "calories",
      responsive: ["xxl", "xl", "lg", "md"],
    },
    {
      title: "Действие",
      dataIndex: "action",
      key: "action",
      align: "right",
      render: (_, record) => (
        <TableActionsMenu
          actions={[
            {
              title: "Редактировать",
              url: frontendPaths.programm_edit.URL(record.code),
            },
            {
              title: "Удалить",
              url: null,
              action: () => handleTurnOnProgrammDeleteMode(record.code),
            },
          ]}
        />
      ),
    },
  ];

  return (
    <>
      <PageHeader
        title={frontendPaths.programms.title}
        onBack={() => navigate(-1)}
        extra={
          <Link to={frontendPaths.programm_create.URL()}>
            <Button type="primary">
              {frontendPaths.programm_create.title}
            </Button>
          </Link>
        }
      />
      <Card>
        <ShowErrorMessages />
        <Table
          dataSource={programms}
          columns={columns}
          loading={loading === "loading"}
        />
      </Card>
      {removableProgramm && (
        <DeleteConfirmModal
          isOpen={isProgrammDeleteMode}
          onOk={() => handleDeleteProgrammSubmit(removableProgramm.code)}
          onCancel={handleDeleteProgrammCancel}
          text={`Удалить программу: ${removableProgramm.name}?`}
        />
      )}
    </>
  );
}

export default Programms;
