import { Button, Card, PageHeader, Pagination, Table } from "antd";
import { ColumnProps } from "antd/lib/table";
import { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";

import { ImagePreview } from "@/components/ImagePreview";
import DeleteConfirmModal from "@/components/modals/DeleteConfirmModal";
import { ShowErrorMessages, errorActions } from "@/entities/error";
import TableActionsMenu from "@/components/TableActionsMenu";
import TooltipDate from "@/components/TooltipDate";
import { useAppDispatch, useAppSelector } from "@/app";
import frontendPaths from "@/routes/paths";
import addReactKey from "@/shared/utils/addReactKey";
import {
  INewsListItem,
  deleteNews,
  fetchNews,
  newsSelectors,
} from "@/entities/news";

function News() {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  const [entityToDelete, setEntityToDelete] = useState<string | null>(null);

  useEffect(() => {
    void dispatch(errorActions.resetErrors());
    void dispatch(fetchNews(1));
  }, []);

  const newsData = useAppSelector(({ news }) => news);
  const { loading, pagination } = newsData;
  const news = addReactKey<INewsListItem>(
    useAppSelector(newsSelectors.selectAll)
  );

  const onPageChange = (page: number) => {
    void dispatch(fetchNews(page));
  };

  // delete news
  const handleDeleteEntitySubmit = (code: string | null) => {
    if (code) {
      void dispatch(deleteNews(code));
      setEntityToDelete(null);
    }
  };

  const handleDeleteEntityCancel = () => {
    setEntityToDelete(null);
  };

  const columns: ColumnProps<INewsListItem>[] = [
    {
      title: "Заголовок",
      dataIndex: "title",
      key: "title",
    },
    {
      title: "Фото",
      dataIndex: "photo",
      key: "photo",
      render: (_, record) =>
        record?.photo ? (
          <ImagePreview
            photoCode={record.photo.code}
            imageSrc={record.photo.urlPath}
          />
        ) : null,
    },
    {
      title: "Дата",
      dataIndex: "date",
      key: "date",
      render: (_, record) => <TooltipDate date={record.date} />,
    },
    {
      title: "Действие",
      dataIndex: "action",
      key: "action",
      align: "right",
      render: (_, record) => (
        <TableActionsMenu
          actions={[
            {
              title: "Редактировать",
              url: frontendPaths.news_edit.URL(record.code),
            },
            {
              title: "Удалить",
              url: null,
              action: () => setEntityToDelete(record.code),
            },
          ]}
        />
      ),
    },
  ];

  return (
    <>
      <PageHeader
        title="Новости"
        onBack={() => navigate(-1)}
        extra={
          <Link to={frontendPaths.news_create.URL()}>
            <Button type="primary">{frontendPaths.news_create.title}</Button>
          </Link>
        }
      />
      <Card>
        <ShowErrorMessages />
        <Table
          dataSource={news}
          columns={columns}
          pagination={false}
          loading={loading === "loading"}
        />
        {pagination && (
          <Pagination
            current={pagination.currentPage}
            pageSize={pagination.pageSize}
            total={pagination.total}
            showSizeChanger={false}
            onChange={onPageChange}
          />
        )}
      </Card>
      <DeleteConfirmModal
        isOpen={Boolean(entityToDelete)}
        onOk={() => handleDeleteEntitySubmit(entityToDelete)}
        onCancel={handleDeleteEntityCancel}
        text={`новость: ${
          news.find((el) => el.code === entityToDelete)?.title
        }`}
      />
    </>
  );
}

export default News;
