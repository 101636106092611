import { Layout } from "antd";
import { ReactNode } from "react";

import Logo from "@/components/Logo";
import { ShowToastMessage } from "@/entities/error";

type LayoutTemplateProperties = {
  children: ReactNode;
};

const { Header, Content, Footer } = Layout;

function PublicLayoutTemplate({ children }: LayoutTemplateProperties) {
  return (
    <Layout className="public-layout">
      <Header>
        <Logo />
      </Header>
      <ShowToastMessage />
      <Content className="site-layout-content">{children}</Content>
      <Footer className="site-footer">© Interso 2022</Footer>
    </Layout>
  );
}

export default PublicLayoutTemplate;
