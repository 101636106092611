import { createAsyncThunk } from "@reduxjs/toolkit";
import axios, { AxiosError } from "axios";

import { PartialBy } from "@/shared/models/slice";
import backendPaths from "@/constants/backendPaths";
import { IThunkConfig } from "@/app";
import { errorActions } from "@/entities/error";

import { IDocEditRequest } from "../types/docs";

export const editDoc = createAsyncThunk<null, IDocEditRequest, IThunkConfig>(
  "docs/editDoc",
  async (doc: IDocEditRequest, thunkAPI) => {
    const { rejectWithValue, dispatch } = thunkAPI;
    try {
      const docCode = doc.code;
      const request: PartialBy<IDocEditRequest, "code"> = {
        ...doc,
      };
      delete request.code;
      const response = await axios.post(
        backendPaths.DOC_EDIT_URL(docCode),
        JSON.stringify(request)
      );
      dispatch(
        errorActions.addSuccessMessage({
          message: "Документ обновлен",
          type: "success",
        })
      );
      return response.data as null;
    } catch (err) {
      if (err instanceof AxiosError) return rejectWithValue(err.response?.data);
      throw err;
    }
  }
);
