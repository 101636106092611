import { Form, Input, Modal } from "antd";

import { IClubAreasItem } from "@/entities/club/model/types/rooms";
import { IThunkCustomError, ShowErrorMessages } from "@/entities/error";

interface IAddModalProps {
  isOpen: boolean;
  onOk: (values: { name: string }) => void;
  onCancel: () => void;
  messages?: IThunkCustomError[];
  area: IClubAreasItem;
}

function RoomEditModal({
  isOpen,
  onOk,
  onCancel,
  messages,
  area,
}: IAddModalProps) {
  const [form] = Form.useForm();

  return (
    <Modal
      title="Редактирование зала"
      open={isOpen}
      onOk={form.submit}
      onCancel={onCancel}
    >
      {messages && <ShowErrorMessages />}
      <Form
        form={form}
        onFinish={onOk}
        initialValues={{
          name: area.name,
        }}
      >
        <Form.Item
          label="Наименование зала"
          name="name"
          rules={[
            {
              required: true,
              message: "Пожалуйста укажите наименование зала",
            },
          ]}
        >
          <Input />
        </Form.Item>
      </Form>
    </Modal>
  );
}

export default RoomEditModal;
