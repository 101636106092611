import { Button, Card, Form, Input, InputNumber, PageHeader } from "antd";
import { useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";

import Preloader from "@/components/Preloader";
import { ShowErrorMessages, errorActions } from "@/entities/error";
import * as form from "@/constants/formsWrappers";
import { useAppDispatch, useAppSelector } from "@/app";
import frontendPaths from "@/routes/paths";
import {
  IProgrammEditRequest,
  IProgrammEditValues,
  editProgramm,
  fetchProgramm,
  programmsSelectors,
} from "@/entities/programms";

import PageNotFound from "./PageNotFound";

const { TextArea } = Input;

function ProgrammEdit() {
  const param = useParams();
  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  useEffect(() => {
    void dispatch(errorActions.resetErrors());
    param.id && void dispatch(fetchProgramm(param.id));
  }, []);

  const programmsData = useAppSelector(({ programms }) => programms);
  const { loading } = programmsData;

  const programm = useAppSelector((state) =>
    param.id ? programmsSelectors.selectById(state, param.id) : undefined
  );

  const handleProgrammEdit = (values: IProgrammEditValues) => {
    console.log("values: ", values);
    if (programm) {
      const request: IProgrammEditRequest = {
        code: programm.code,
        ...values,
      };
      void dispatch(editProgramm(request));
    }
  };

  if (!param.id || loading === "failed") {
    return <PageNotFound />;
  }

  if (loading === "loading") {
    return <Preloader />;
  }

  return programm ? (
    <>
      <PageHeader
        title={`${frontendPaths.programm_edit.title}: ${programm.name}`}
        onBack={() => navigate(-1)}
      />
      {programm && (
        <Card>
          <ShowErrorMessages />
          <Form
            name="editCoach"
            labelCol={form.LebelColWide}
            wrapperCol={form.WrapperColWide}
            autoComplete="off"
            initialValues={{
              name: programm.name,
              description: programm.description,
              duration: programm.duration,
              calories: programm.calories,
            }}
            onFinish={handleProgrammEdit}
          >
            <Form.Item
              label="Название программы"
              name="name"
              rules={[
                {
                  required: true,
                  message: "Пожалуйста укажите название программы",
                },
              ]}
            >
              <Input />
            </Form.Item>

            <Form.Item
              label="Продолжительность, мин"
              name="duration"
              rules={[
                {
                  required: true,
                  message: "Пожалуйста укажите продолжительность программы",
                },
              ]}
            >
              <InputNumber />
            </Form.Item>

            <Form.Item
              label="Калорий"
              name="calories"
              rules={[
                {
                  required: true,
                  message: "Пожалуйста укажите количество калорий",
                },
              ]}
            >
              <InputNumber />
            </Form.Item>

            <Form.Item
              label="Краткое описание"
              name="description"
              rules={[
                {
                  required: true,
                  message: "Пожалуйста укажите краткое описание",
                },
              ]}
            >
              <TextArea rows={4} showCount maxLength={100} />
            </Form.Item>

            <Form.Item wrapperCol={form.SubmitCol}>
              <Button type="primary" htmlType="submit">
                Сохранить
              </Button>
            </Form.Item>
          </Form>
        </Card>
      )}
    </>
  ) : null;
}

export default ProgrammEdit;
