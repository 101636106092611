import { useNavigate } from "react-router-dom";

import { useAppDispatch } from "@/app";
import { logOut } from "@/entities/auth";

export const useLogout = () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  return function Logout() {
    void dispatch(logOut());
    navigate("/");
  };
};
