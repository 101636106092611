import { Button, Card, PageHeader, Table, Tag } from "antd";
import { ColumnProps } from "antd/lib/table";
import {
  ColumnFilterItem,
  FilterValue,
  SorterResult,
  TablePaginationConfig,
} from "antd/lib/table/interface";
import { isEqual } from "lodash";
import { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";

import { ImagePreview } from "@/components/ImagePreview";
import DeleteConfirmModal from "@/components/modals/DeleteConfirmModal";
import { ShowErrorMessages, errorActions } from "@/entities/error";
import TableActionsMenu from "@/components/TableActionsMenu";
import { useAppDispatch, useAppSelector } from "@/app";
import {
  coachTypes,
  ICoachListItem,
} from "@/entities/coachs/model/types/coachs";
import frontendPaths from "@/routes/paths";
import addReactKey from "@/shared/utils/addReactKey";
import getFullName from "@/shared/utils/getFullName";
import { convertSorterConfig, getSorterOrder } from "@/shared/utils/sorters";
import {
  coachActions,
  coachsSelectors,
  deleteCoach,
  fetchCoachs,
} from "@/entities/coachs";
import { clubsSelectors } from "@/entities/club";

function Coachs() {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  const [isCoachDeleteMode, setCoachDeleteMode] = useState(false);
  const [removableCoach, setRemovableCoah] = useState<ICoachListItem | null>(
    null
  );

  useEffect(() => {
    void dispatch(errorActions.resetErrors());
    void dispatch(fetchCoachs());
  }, []);

  const coachsData = useAppSelector(({ coachs }) => coachs);
  const { loading } = coachsData;
  const coachs = addReactKey<ICoachListItem>(
    useAppSelector(coachsSelectors.selectAll)
  );
  const clubs = useAppSelector(clubsSelectors.selectAll);

  const clubsFilter: ColumnFilterItem[] = clubs.map((item) => ({
    key: item.code,
    text: item.name,
    value: item.code,
  }));

  // delete coach
  const handleTurnOnCoachDeleteMode = (code: string) => {
    const coachToRemove = coachs.find((item) => item.code === code);
    if (coachToRemove) {
      setCoachDeleteMode(true);
      setRemovableCoah(coachToRemove);
    }
  };

  const handleDeleteCoachSubmit = (code: string) => {
    void dispatch(deleteCoach(code));
    setCoachDeleteMode(false);
    setRemovableCoah(null);
  };

  const handleDeleteCoachCancel = () => {
    setCoachDeleteMode(false);
    setRemovableCoah(null);
  };

  const onTableChange = (
    _: TablePaginationConfig,
    filters: Record<string, FilterValue | null>,
    sorter: SorterResult<ICoachListItem> | SorterResult<ICoachListItem>[]
  ) => {
    const storeFilters = coachsData.filters;
    const filtersChanged = !isEqual(filters, storeFilters);
    filtersChanged && dispatch(coachActions.setFilters(filters));
    const storeSorter = coachsData.sorter;
    const convertedSorter = convertSorterConfig<ICoachListItem>(sorter);
    const sorterChanged = !isEqual(storeSorter, convertedSorter);
    sorterChanged && dispatch(coachActions.setSorter(convertedSorter));
  };

  const columns: ColumnProps<ICoachListItem>[] = [
    {
      title: "Фаимилия Имя",
      dataIndex: "name",
      key: "name",
      width: "50%",
      sorter: (a, b) => a.lastName.localeCompare(b.lastName),
      defaultSortOrder: getSorterOrder(coachsData?.sorter, "name"),
      render: (_, record) => getFullName(record.lastName, record.firstName),
    },
    {
      title: "Фото",
      dataIndex: "photo",
      key: "photo",
      render: (_, record) =>
        record?.photo ? (
          <ImagePreview
            photoCode={record.photo.code}
            imageSrc={record.photo.urlPath}
          />
        ) : null,
    },
    {
      title: "Телефон",
      dataIndex: "phone",
      key: "phone",
      width: "20%",
      responsive: ["xxl", "xl", "lg", "md"],
    },
    {
      title: "Тип",
      dataIndex: "jobType",
      key: "jobType",
      width: "20%",
      responsive: ["xxl", "xl", "lg", "md"],
      render: (_, record) =>
        coachTypes.find((el) => el.value === record.jobType)?.label,
    },
    {
      title: "Клуб",
      dataIndex: "clubs",
      key: "clubs",
      responsive: ["xxl", "xl", "lg", "md"],
      filters: clubsFilter,
      onFilter: (value, record) =>
        record.clubs &&
        record.clubs.filter((item) => item.code === value).length > 0,
      defaultFilteredValue: coachsData.filters.clubs,
      render: (_, record) =>
        record.clubs.map((item) => <Tag key={item.code}>{item.name}</Tag>),
    },
    {
      title: "Действие",
      dataIndex: "action",
      key: "action",
      align: "right",
      render: (_, record) => (
        <TableActionsMenu
          actions={[
            {
              title: "Редактировать",
              url: frontendPaths.coach_edit.URL(record.code),
            },
            {
              title: "Удалить",
              url: null,
              action: () => handleTurnOnCoachDeleteMode(record.code),
            },
          ]}
        />
      ),
    },
  ];

  return (
    <>
      <PageHeader
        title={frontendPaths.coachs.title}
        onBack={() => navigate(-1)}
        extra={
          <Link to={frontendPaths.coach_create.URL()}>
            <Button type="primary">{frontendPaths.coach_create.title}</Button>
          </Link>
        }
      />
      <Card>
        <ShowErrorMessages />
        <Table
          dataSource={coachs}
          columns={columns}
          loading={loading === "loading"}
          onChange={onTableChange}
        />
      </Card>
      {removableCoach && (
        <DeleteConfirmModal
          isOpen={isCoachDeleteMode}
          onOk={() => handleDeleteCoachSubmit(removableCoach.code)}
          onCancel={handleDeleteCoachCancel}
          text={`Удалить тренера: ${getFullName(
            removableCoach.firstName,
            removableCoach.lastName
          )}?`}
        />
      )}
    </>
  );
}

export default Coachs;
