import { Button, Card, Checkbox, Form, Input, PageHeader, Select } from "antd";
import { useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";

import Preloader from "@/components/Preloader";
import { ShowErrorMessages, errorActions } from "@/entities/error";
import TextEditor from "@/components/TextEditor";
import * as form from "@/constants/formsWrappers";
import { slugRegExp } from "@/constants/params";
import { useAppDispatch, useAppSelector } from "@/app";
import frontendPaths from "@/routes/paths";
import {
  IDocEditRequest,
  IDocEditValues,
  editDoc,
  fetchDoc,
} from "@/entities/docs";

import { docsColorOptions } from "./DocCreate";
import PageNotFound from "./PageNotFound";

function DocEdit() {
  const param = useParams();
  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  useEffect(() => {
    void dispatch(errorActions.resetErrors());
    param.id && dispatch(fetchDoc(param.id));
  }, []);

  const docsData = useAppSelector(({ docs }) => docs);
  const { docDetail, loading } = docsData;

  const handleDocEdit = (values: IDocEditValues) => {
    if (docDetail) {
      const request: IDocEditRequest = {
        code: docDetail.code,
        ...values,
      };
      void dispatch(editDoc(request));
    }
  };

  if (!param.id || loading === "failed") {
    return <PageNotFound />;
  }

  if (loading === "loading") {
    return <Preloader />;
  }

  return docDetail ? (
    <>
      <PageHeader
        title={`${frontendPaths.doc_edit.title}: ${docDetail.name}`}
        onBack={() => navigate(-1)}
      />
      <Card>
        <ShowErrorMessages />
        <Form
          name="editDoc"
          labelCol={form.LebelColWide}
          wrapperCol={form.WrapperColWide}
          autoComplete="off"
          onFinish={handleDocEdit}
          initialValues={{
            name: docDetail.name,
            color: docDetail.color,
            mainPageShow: docDetail.mainPageShow,
            description: docDetail.description,
            slug: docDetail.slug,
          }}
        >
          <Form.Item
            label="Заголовок"
            name="name"
            rules={[
              {
                required: true,
                message: "Пожалуйста укажите заголовок документа",
              },
            ]}
          >
            <Input />
          </Form.Item>

          <Form.Item
            label="slug"
            name="slug"
            rules={[
              {
                required: true,
                message: "Пожалуйста укажите slug",
              },
              {
                required: true,
                pattern: new RegExp(slugRegExp),
                message: "Только латинские буквы и цифры",
              },
            ]}
          >
            <Input />
          </Form.Item>

          <Form.Item
            label="Цвет"
            name="color"
            rules={[
              {
                required: true,
                message: "Пожалуйста укажите цвет ярлыка документа",
              },
            ]}
          >
            <Select options={docsColorOptions} />
          </Form.Item>

          <Form.Item
            label="Показывать на главной?"
            name="mainPageShow"
            valuePropName="checked"
          >
            <Checkbox>Да</Checkbox>
          </Form.Item>

          <Form.Item
            label="Описание"
            name="description"
            rules={[
              {
                required: true,
                message: "Пожалуйста укажите описание программы",
              },
            ]}
          >
            <TextEditor value="description" />
          </Form.Item>

          <Form.Item wrapperCol={form.SubmitCol}>
            <Button type="primary" htmlType="submit">
              Сохранить
            </Button>
          </Form.Item>
        </Form>
      </Card>
    </>
  ) : null;
}

export default DocEdit;
