import {
  Button,
  Card,
  DatePicker,
  Form,
  Input,
  PageHeader,
  Upload,
} from "antd";
import dayjs from "dayjs";
import { useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";

import Preloader from "@/components/Preloader";
import { ShowErrorMessages, errorActions } from "@/entities/error";
import TextEditor from "@/components/TextEditor";
import * as form from "@/constants/formsWrappers";
import { useAppDispatch, useAppSelector } from "@/app";
import {
  convertIFileResponseToPhotoListItem,
  createInitUploadConfig,
} from "@/shared/models/files";
import frontendPaths from "@/routes/paths";
import customUpload, { normFile } from "@/shared/utils/customUpload";
import { editNews, fetchNewsDetail } from "@/entities/news";
import {
  INewsEditArgs,
  INewsEditValues,
} from "@/entities/news/model/types/news";

import PageNotFound from "./PageNotFound";

function NewsEdit() {
  const param = useParams();
  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  useEffect(() => {
    void dispatch(errorActions.resetErrors());
    param.id && dispatch(fetchNewsDetail(param.id));
  }, []);

  const newsDetailData = useAppSelector(({ news }) => news);
  const { newsDetail, loading } = newsDetailData;

  const initFile = createInitUploadConfig(newsDetail?.photo);

  const handleNewsEdit = (values: INewsEditValues) => {
    if (!newsDetail || !values.photo || !values.photo[0]?.response?.code)
      return;

    const request: INewsEditArgs = {
      code: newsDetail.code,
      date: dayjs(values.date).format("YYYY-MM-DD HH:mm:ss"),
      title: values.title,
      content: values.content,
      photo: convertIFileResponseToPhotoListItem(values.photo[0].response),
    };
    void dispatch(editNews(request));
  };

  if (!param.id || loading === "failed") {
    return <PageNotFound />;
  }

  if (loading === "loading") {
    return <Preloader />;
  }

  return newsDetail ? (
    <>
      <PageHeader
        title={`${frontendPaths.news_edit.title}: ${newsDetail.title}`}
        onBack={() => navigate(-1)}
      />
      <Card>
        <ShowErrorMessages />
        {newsDetail ? (
          <Form
            name="editNews"
            labelCol={form.LebelColWide}
            wrapperCol={form.WrapperColWide}
            autoComplete="off"
            initialValues={{
              title: newsDetail.title,
              date: dayjs(newsDetail.date, "YYYY-MM-DD hh:mm:ss"),
              content: newsDetail.content,
              photo: initFile,
            }}
            onFinish={handleNewsEdit}
          >
            <Form.Item
              label="Заголовок новости"
              name="title"
              rules={[
                {
                  required: true,
                  message: "Пожалуйста укажите заголовок новости",
                },
              ]}
            >
              <Input />
            </Form.Item>

            <Form.Item
              label="Дата и время"
              name="date"
              rules={[
                {
                  required: true,
                  message: "Пожалуйста укажите дату и время новости",
                },
              ]}
            >
              <DatePicker
                showTime={{
                  format: "HH:mm",
                }}
              />
            </Form.Item>

            <Form.Item
              label="Текст новости"
              name="content"
              rules={[
                {
                  required: true,
                  message: "Пожалуйста укажите текст новости",
                },
              ]}
            >
              <TextEditor value="content" placeholder="Текст новости" />
            </Form.Item>

            <Form.Item
              label="Фото новости"
              name="photo"
              valuePropName="fileList"
              getValueFromEvent={normFile}
              rules={[
                {
                  required: true,
                  message: "Пожалуйста укажите фото новости",
                },
              ]}
            >
              <Upload
                accept="image/*"
                customRequest={customUpload}
                listType="picture-card"
                multiple={false}
                maxCount={1}
                defaultFileList={initFile}
              >
                <Button type="link">Загрузить</Button>
              </Upload>
            </Form.Item>

            <Form.Item wrapperCol={form.SubmitCol}>
              <Button type="primary" htmlType="submit">
                Сохранить
              </Button>
            </Form.Item>
          </Form>
        ) : null}
      </Card>
    </>
  ) : null;
}

export default NewsEdit;
