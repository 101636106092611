import {
  Button,
  Card,
  DatePicker,
  Form,
  Input,
  InputNumber,
  PageHeader,
  Radio,
  Select,
  Switch,
} from "antd";
import dayjs from "dayjs";
import { useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";

import Preloader from "@/components/Preloader";
import { ShowErrorMessages, errorActions } from "@/entities/error";
import * as form from "@/constants/formsWrappers";
import { defaultBackendDateFormat, promocodeRegExp } from "@/constants/params";
import { useAppDispatch, useAppSelector } from "@/app";
import frontendPaths from "@/routes/paths";
import { fetchAllProducts } from "@/entities/products";
import {
  IPromocodeEditRequest,
  IPromocodeEditValues,
  editPromocode,
  fetchPromocode,
} from "@/entities/promocodes";

import PageNotFound from "./PageNotFound";

const { TextArea } = Input;

function ProductEdit() {
  const param = useParams();
  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  useEffect(() => {
    void dispatch(errorActions.resetErrors());
    void dispatch(fetchAllProducts());
    param.id && dispatch(fetchPromocode(param.id));
  }, []);

  const promocodeData = useAppSelector(({ promocodes }) => promocodes);
  const { promocodeDetail, loading } = promocodeData;

  const { allProducts } = useAppSelector(({ products }) => products);
  const productsOptions = allProducts?.map((item) => ({
    value: item.code,
    label: item.title,
  }));

  const handleProductEdit = (values: IPromocodeEditValues) => {
    if (promocodeDetail) {
      const request: IPromocodeEditRequest = {
        code: promocodeDetail.code,
        active: values.active,
        secret: values.secret,
        type: values.type,
        discount: values.discount,
        note: values.note,
        startAt: dayjs(values.startAt).format(defaultBackendDateFormat),
        endAt: dayjs(values.endAt).format(defaultBackendDateFormat),
        productCodes: values.productCodes,
      };
      void dispatch(editPromocode(request));
    }
  };

  if (!param.id || loading === "failed") {
    return <PageNotFound />;
  }

  if (loading === "loading") {
    return <Preloader />;
  }

  return promocodeDetail ? (
    <>
      <PageHeader
        title={`${frontendPaths.promocode_edit.title}: ${promocodeDetail.secret}`}
        onBack={() => navigate(-1)}
      />
      <Card>
        <ShowErrorMessages />
        <Form
          name="editPromocode"
          labelCol={form.LebelColWide}
          wrapperCol={form.WrapperColWide}
          autoComplete="off"
          initialValues={{
            secret: promocodeDetail.secret,
            type: promocodeDetail.type,
            discount: promocodeDetail.discount,
            note: promocodeDetail.note,
            startAt: dayjs(promocodeDetail.startAt, "YYYY-MM-DD"),
            endAt: dayjs(promocodeDetail.endAt, "YYYY-MM-DD"),
            productCodes: promocodeDetail.productCodes,
          }}
          onFinish={handleProductEdit}
        >
          <Form.Item
            label="Активен"
            name="active"
            valuePropName="checked"
            initialValue
          >
            <Switch />
          </Form.Item>

          <Form.Item
            label="Промокод"
            name="secret"
            rules={[
              {
                required: true,
                message: "Пожалуйста укажите промокод",
              },
              {
                required: true,
                pattern: new RegExp(promocodeRegExp),
                message:
                  "Допускаются только латинские или русские буквы, цифры и дефис",
              },
            ]}
          >
            <Input />
          </Form.Item>

          <Form.Item
            label="Тип скидки"
            name="type"
            rules={[
              {
                required: true,
                message: "Пожалуйста выберите тип скидки",
              },
            ]}
          >
            <Radio.Group value={promocodeDetail.type}>
              <Radio value="absolute">в рублях</Radio>
              <Radio value="percent">в процентах</Radio>
            </Radio.Group>
          </Form.Item>

          <Form.Item
            label="Скидка"
            name="discount"
            rules={[
              {
                required: true,
                message: "Пожалуйста укажите скидку в рублях",
              },
            ]}
          >
            <InputNumber precision={2} stringMode />
          </Form.Item>

          <Form.Item
            label="Примечание"
            name="note"
            rules={[
              {
                required: true,
                message: "Пожалуйста укажите примечание",
              },
            ]}
          >
            <TextArea rows={4} />
          </Form.Item>

          <Form.Item
            label="Действует от"
            name="startAt"
            rules={[
              {
                required: true,
                message: "Пожалуйста укажите дату начала",
              },
            ]}
          >
            <DatePicker />
          </Form.Item>

          <Form.Item
            label="Действует до"
            name="endAt"
            rules={[
              {
                required: true,
                message: "Пожалуйста укажите дату окончания",
              },
            ]}
          >
            <DatePicker />
          </Form.Item>

          <Form.Item
            label="Акции"
            name="productCodes"
            rules={[
              {
                required: true,
                message: "Пожалуйста выберите акцию из списка",
              },
            ]}
          >
            <Select
              options={productsOptions}
              mode="multiple"
              showSearch
              optionFilterProp="children"
              placeholder="Введите название акции"
              filterOption={(input, option) =>
                (option?.label ?? "").includes(input)
              }
              filterSort={(optionA, optionB) =>
                (optionA?.label ?? "")
                  .toLowerCase()
                  .localeCompare((optionB?.label ?? "").toLowerCase())
              }
            />
          </Form.Item>

          <Form.Item wrapperCol={form.SubmitCol}>
            <Button type="primary" htmlType="submit">
              Сохранить
            </Button>
          </Form.Item>
        </Form>
      </Card>
    </>
  ) : null;
}

export default ProductEdit;
