import { Card, PageHeader, Table } from "antd";
import { ColumnProps } from "antd/lib/table";
import { Link, useNavigate } from "react-router-dom";
import { useEffect } from "react";

import { ShowErrorMessages, errorActions } from "@/entities/error";
import frontendPaths from "@/routes/paths";
import addReactKey from "@/shared/utils/addReactKey";
import { IClubListItem, clubsSelectors, getClubLoading } from "@/entities/club";
import { useAppDispatch, useAppSelector } from "@/app";

function Clubs() {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  const loading = useAppSelector(getClubLoading);
  const clubs = addReactKey(useAppSelector(clubsSelectors.selectAll));

  useEffect(() => {
    void dispatch(errorActions.resetErrors());
  }, []);

  const columns: ColumnProps<IClubListItem>[] = [
    {
      title: "Клуб",
      dataIndex: "name",
      key: "name",
      render: (_, record) => (
        <Link to={frontendPaths.club.URL(record.code)}>{record.name}</Link>
      ),
    },
    {
      title: "Телефон",
      dataIndex: "contactPhone",
      key: "contactPhone",
    },
  ];

  return (
    <>
      <PageHeader
        title={frontendPaths.clubs.title}
        onBack={() => navigate(-1)}
      />
      <Card>
        <ShowErrorMessages />
        <Table
          dataSource={clubs}
          columns={columns}
          pagination={false}
          loading={loading === "loading"}
        />
      </Card>
    </>
  );
}

export default Clubs;
