import {
  Button,
  Card,
  Form,
  Input,
  InputNumber,
  PageHeader,
  Select,
} from "antd";
import { useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";

import { ShowErrorMessages, errorActions } from "@/entities/error";
import * as form from "@/constants/formsWrappers";
import { useAppDispatch, useAppSelector } from "@/app";
import frontendPaths from "@/routes/paths";
import {
  IClubEditAddressValues,
  editAddress,
  fetchClub,
  fetchClubAddress,
  getClubAddress,
  getClubLoading,
} from "@/entities/club";
import {
  fetchstreetTypes,
  streetTypesSelectors,
} from "@/entities/streetTypesSlice";

import PageNotFound from "./PageNotFound";

function ClubAddressEdit() {
  const param = useParams();
  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  useEffect(() => {
    dispatch(errorActions.resetErrors());
    if (param.id) {
      void dispatch(fetchstreetTypes());
      void dispatch(fetchClub(param.id));
      void dispatch(fetchClubAddress(param.id));
    }
  }, [param]);

  const loading = useAppSelector(getClubLoading);
  const clubAddress = useAppSelector(getClubAddress);

  const streetTypes = useAppSelector(streetTypesSelectors.selectAll).map(
    (item) => ({
      value: item.name,
      label: item.title,
    })
  );

  const handleUpdateClubAddress = (values: IClubEditAddressValues) => {
    if (param.id) {
      const request = {
        code: param.id,
        ...values,
      };
      void dispatch(editAddress(request));
    }
  };

  if (!param.id) {
    return <PageNotFound />;
  }

  return clubAddress ? (
    <>
      <PageHeader
        title={`${frontendPaths.club_address_edit.title}: ${clubAddress.street}`}
        onBack={() => navigate(-1)}
      />
      <Card>
        <ShowErrorMessages />
        <Form
          name="editClub"
          labelCol={form.LebelCol}
          wrapperCol={form.WrapperCol}
          autoComplete="off"
          disabled={loading === "loading"}
          initialValues={{
            city: clubAddress?.city,
            streetType: clubAddress?.streetType,
            street: clubAddress?.street,
            house: clubAddress?.house,
            entrance: clubAddress?.entrance,
            longtitude: clubAddress?.longtitude,
            latitude: clubAddress?.latitude,
          }}
          onFinish={handleUpdateClubAddress}
        >
          <Form.Item
            label="Город"
            name="city"
            rules={[
              {
                required: true,
                message: "Пожалуйста укажите город клуба",
              },
            ]}
          >
            <Input />
          </Form.Item>

          <Form.Item
            label="Тип улицы"
            name="streetType"
            rules={[
              {
                required: true,
                message: "Пожалуйста укажите тип улицы клуба",
              },
            ]}
          >
            <Select options={streetTypes} />
          </Form.Item>

          <Form.Item
            label="Наименование улицы"
            name="street"
            rules={[
              {
                required: true,
                message: "Пожалуйста укажите наименование улицы клуба",
              },
            ]}
          >
            <Input />
          </Form.Item>

          <Form.Item
            label="Дом"
            name="house"
            rules={[
              {
                required: true,
                message: "Пожалуйста укажите примечание дом клуба",
              },
            ]}
          >
            <Input />
          </Form.Item>

          <Form.Item
            label="Вход"
            name="entrance"
            rules={[
              {
                required: true,
                message: "Пожалуйста укажите вход клуба",
              },
            ]}
          >
            <Input />
          </Form.Item>

          <Form.Item
            label="Широта"
            name="latitude"
            rules={[
              {
                required: true,
                message: "Пожалуйста укажите широту клуба",
              },
            ]}
          >
            <InputNumber controls={false} precision={6} stringMode />
          </Form.Item>

          <Form.Item
            label="Долгота"
            name="longtitude"
            rules={[
              {
                required: true,
                message: "Пожалуйста укажите долготу клуба",
              },
            ]}
          >
            <InputNumber controls={false} precision={6} stringMode />
          </Form.Item>

          <Form.Item wrapperCol={form.SubmitCol}>
            <Button type="primary" htmlType="submit">
              Сохранить
            </Button>
          </Form.Item>
        </Form>
      </Card>
    </>
  ) : null;
}

export default ClubAddressEdit;
