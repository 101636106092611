import { Table, Tag, Tooltip } from "antd";
import { ColumnProps } from "antd/lib/table";
import dayjs from "dayjs";
import { DollarCircleOutlined } from "@ant-design/icons";

import TableActionsMenu from "@/components/TableActionsMenu";
import { IScheduleListItem } from "@/entities/schedule";
import frontendPaths from "@/routes/paths";
import getFullName from "@/shared/utils/getFullName";
import { IRowSelection } from "@/shared/hooks/useTableRowSelection";

type TProps = {
  events: IScheduleListItem[];
  onDelete: (entity: string | null) => void;
  loading: string;
  rowSelection: IRowSelection;
};

export function TableView(props: TProps) {
  const { events, loading, onDelete, rowSelection } = props;

  const columns: ColumnProps<IScheduleListItem>[] = [
    {
      title: "Дата",
      dataIndex: "startedAt",
      key: "startedAt",
      render: (_, record) => (
        <div style={{ whiteSpace: "nowrap" }}>
          {dayjs(record.startedAt).format("YYYY-MM-DD")}
        </div>
      ),
      width: 150,
    },
    {
      title: "Время",
      dataIndex: "startedAt",
      key: "startedAt",
      render: (_, record) => dayjs(record.startedAt).format("HH:mm"),
      width: "150px",
    },
    {
      title: "День недели",
      dataIndex: "weekday",
      key: "weekday",
      render: (_, record) => (
        <Tag>{dayjs(record.startedAt).format("dddd")}</Tag>
      ),
    },
    {
      title: "Программа",
      dataIndex: "name",
      key: "name",
      width: "20%",
      render: (_, record) => (
        <Tooltip title={record.program.description}>
          {record.program.name}
        </Tooltip>
      ),
    },
    {
      title: "Зал",
      dataIndex: "area",
      key: "area",
      width: "20%",
      responsive: ["xxl", "xl", "lg", "md"],
      render: (_, record) => `${record.area.name} (${record.area.club.name})`,
    },
    {
      title: "Тренер",
      dataIndex: "teacher",
      key: "teacher",
      width: "20%",
      responsive: ["xxl", "xl", "lg", "md"],
      render: (_, record) =>
        getFullName(record.teacher.firstName, record.teacher.lastName),
    },
    {
      title: "Платная",
      dataIndex: "paid",
      key: "paid",
      align: "center",
      responsive: ["xxl", "xl", "lg", "md"],
      render: (_, record) => record.paid && <DollarCircleOutlined />,
    },
    {
      title: "Действие",
      dataIndex: "action",
      key: "action",
      align: "right",
      render: (_, record) => (
        <TableActionsMenu
          actions={[
            {
              title: "Редактировать",
              url: frontendPaths.schedule_edit.URL(record.code),
            },
            {
              title: "Удалить",
              url: null,
              action: () => onDelete(record.code),
            },
          ]}
        />
      ),
    },
  ];

  return (
    <Table
      dataSource={events}
      columns={columns}
      loading={loading === "loading"}
      rowSelection={rowSelection}
      rowClassName={(record) =>
        dayjs(record.startedAt).diff(dayjs(), "days") < 0
          ? "row-past-event"
          : ""
      }
    />
  );
}
