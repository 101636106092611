import { Form, Input, Modal } from "antd";
import { useEffect } from "react";

import { IThunkCustomError, ShowErrorMessages } from "@/entities/error";

interface IAddModalProps {
  isOpen: boolean;
  onOk: (values: { name: string }) => void;
  onCancel: () => void;
  messages?: IThunkCustomError[];
}

function RoomAddModal({ isOpen, onOk, onCancel, messages }: IAddModalProps) {
  const [form] = Form.useForm();

  useEffect(() => {
    form.resetFields();
  });

  return (
    <Modal
      title="Добавление зала"
      open={isOpen}
      onOk={form.submit}
      onCancel={onCancel}
    >
      {messages && <ShowErrorMessages />}
      <Form form={form} onFinish={onOk}>
        <Form.Item
          label="Наименование зала"
          name="name"
          rules={[
            {
              required: true,
              message: "Пожалуйста укажите наименование зала",
            },
          ]}
        >
          <Input />
        </Form.Item>
      </Form>
    </Modal>
  );
}

export default RoomAddModal;
