import { createAsyncThunk } from "@reduxjs/toolkit";
import { AxiosError } from "axios";

import { uninterceptedAxios } from "@/api/axios";
import backendPaths from "@/constants/backendPaths";
import { IThunkConfig } from "@/app";

import { ILoginResponse, IUserRequest } from "../types/auth";

export const login = createAsyncThunk<
  ILoginResponse,
  IUserRequest,
  IThunkConfig
>("auth/login", async (user: IUserRequest, thunkAPI) => {
  const { rejectWithValue } = thunkAPI;
  try {
    const response = await uninterceptedAxios.post(
      backendPaths.LOGIN_URL(),
      JSON.stringify(user)
    );
    return response.data as ILoginResponse;
  } catch (err) {
    if (err instanceof AxiosError) return rejectWithValue(err.response?.data);
    throw err;
  }
});
