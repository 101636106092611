import { Button, Card, PageHeader, Pagination, Switch, Table } from "antd";
import { ColumnProps } from "antd/lib/table";
import { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";

import DeleteConfirmModal from "@/components/modals/DeleteConfirmModal";
import { ShowErrorMessages, errorActions } from "@/entities/error";
import TableActionsMenu from "@/components/TableActionsMenu";
import { useAppDispatch, useAppSelector } from "@/app";
import frontendPaths from "@/routes/paths";
import addReactKey from "@/shared/utils/addReactKey";
import {
  deleteSlider,
  fetchSliders,
  ISliderListItem,
  slidersSelectors,
  toggleSlider,
} from "@/entities/sliders";

function Sliders() {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  const [isDocDeleteMode, setDocDeleteMode] = useState(false);
  const [removableDoc, setRemovableDoc] = useState<ISliderListItem | null>(
    null
  );

  useEffect(() => {
    void dispatch(errorActions.resetErrors());
    void dispatch(fetchSliders(1));
  }, []);

  const slidersData = useAppSelector(({ sliders }) => sliders);
  const { loading, pagination } = slidersData;

  const docs = addReactKey<ISliderListItem>(
    useAppSelector(slidersSelectors.selectAll)
  );

  // delete document
  const handleTurnOnDocDeleteMode = (code: string) => {
    const docToRemove = docs.find((item) => item.code === code);
    if (docToRemove) {
      setDocDeleteMode(true);
      setRemovableDoc(docToRemove);
    }
  };

  const handleDeleteDocSubmit = (code: string) => {
    void dispatch(deleteSlider(code));
    setDocDeleteMode(false);
    setRemovableDoc(null);
  };

  const handleDeleteDocCancel = () => {
    setDocDeleteMode(false);
    setRemovableDoc(null);
  };

  const handleTogleSlider = (slider: ISliderListItem) => {
    void dispatch(toggleSlider(slider));
  };

  const onPageChange = (page: number) => {
    void dispatch(fetchSliders(page));
  };

  const columns: ColumnProps<ISliderListItem>[] = [
    {
      title: "Заголовок",
      dataIndex: "title",
      key: "title",
      width: "100%",
    },
    {
      title: "Активен",
      dataIndex: "active",
      key: "active",
      width: "100",
      align: "center",
      render: (_, record) => (
        <Switch
          size="small"
          checked={record.active}
          onChange={() => handleTogleSlider(record)}
        />
      ),
    },
    {
      title: "Действие",
      dataIndex: "action",
      key: "action",
      align: "right",
      render: (_, record) => (
        <TableActionsMenu
          actions={[
            {
              title: "Редактировать",
              url: frontendPaths.slider_edit.URL(record.code),
            },
            {
              title: "Удалить",
              url: null,
              action: () => handleTurnOnDocDeleteMode(record.code),
            },
          ]}
        />
      ),
    },
  ];

  return (
    <>
      <PageHeader
        title="Слайдеры"
        onBack={() => navigate(-1)}
        extra={
          <Link to={frontendPaths.slider_create.URL()}>
            <Button type="primary">{frontendPaths.slider_create.title}</Button>
          </Link>
        }
      />
      <Card>
        <ShowErrorMessages />
        <Table
          dataSource={docs}
          columns={columns}
          pagination={false}
          loading={loading === "loading"}
        />
        {pagination && (
          <Pagination
            current={pagination.currentPage}
            pageSize={pagination.pageSize}
            total={pagination.total}
            showSizeChanger={false}
            onChange={onPageChange}
          />
        )}
      </Card>
      {removableDoc && (
        <DeleteConfirmModal
          isOpen={isDocDeleteMode}
          onOk={() => handleDeleteDocSubmit(removableDoc.code)}
          onCancel={handleDeleteDocCancel}
          text={`Удалить слайдер: ${removableDoc.title}?`}
        />
      )}
    </>
  );
}

export default Sliders;
