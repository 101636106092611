import { createEntityAdapter, createSlice } from "@reduxjs/toolkit";

import { IPhotoListItem } from "@/shared/models/photo";
import { IEntitiesState, PartialBy } from "@/shared/models/slice";

import { IAllAreaItem, IClubAreasItem } from "../types/rooms";
import {
  IClubAddress,
  IClubDetail,
  IClubEditRequest,
  IClubListItem,
  IClubOptionsItem,
} from "../types/clubs";
import { fetchClubs } from "../service/fetchClubs";
import { fetchClub } from "../service/fetchClub";
import { fetchClubAreas } from "../service/fetchClubAreas";
import { fetchClubOptions } from "../service/fetchClubOptions";
import { fetchClubAddress } from "../service/fetchClubAddress";
import { fetchClubPhotos } from "../service/fetchClubPhotos";
import { editClubPhotos } from "../service/editClubPhotos";
import { editClub } from "../service/editClub";
import { fetchAllAreas } from "../service/fetchAllAreas";
import { createClubArea } from "../service/createClubArea";
import { deleteArea } from "../service/deleteArea";
import { editArea } from "../service/editArea";
import { createClubOption } from "../service/createClubOption";
import { editOption } from "../service/editOption";
import { deleteOption } from "../service/deleteOption";
import { editAddress } from "../service/editAddress";

interface IClubsState extends IEntitiesState {
  entities?: IClubListItem[];
  clubDetail: IClubDetail | null;
  clubAddress: IClubAddress | null;
  clubAllAreas: IAllAreaItem[] | null;
  clubAreas: IClubAreasItem[] | null;
  clubOptions: IClubOptionsItem[] | null;
  clubPhotos?: IPhotoListItem[];
}

const initialState: IClubsState = {
  loading: "idle",
  clubDetail: null,
  clubAddress: null,
  clubAllAreas: null,
  clubAreas: null,
  clubOptions: null,
};

export const clubsAdapter = createEntityAdapter<IClubListItem>({
  selectId: (item) => item.code,
});

const clubsSlice = createSlice({
  name: "clubs",
  initialState: clubsAdapter.getInitialState(initialState),
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchClubs.fulfilled, (state, action) => {
        clubsAdapter.addMany(state, action.payload.items);
        state.loading = "idle";
      })
      .addCase(fetchClubs.rejected, (state) => {
        state.loading = "failed";
      })
      .addCase(fetchClubs.pending, (state) => {
        state.loading = "loading";
      })
      // fetching club
      .addCase(fetchClub.fulfilled, (state, action) => {
        state.clubDetail = action.payload.detail;
        state.loading = "idle";
      })
      .addCase(fetchClub.rejected, (state) => {
        state.loading = "failed";
      })
      .addCase(fetchClub.pending, (state) => {
        state.loading = "loading";
      })
      // fetching areas (rooms)
      .addCase(fetchClubAreas.fulfilled, (state, action) => {
        state.clubAreas = action.payload.areas.items;
        state.loading = "idle";
      })
      .addCase(fetchClubAreas.rejected, (state) => {
        state.loading = "failed";
      })
      .addCase(fetchClubAreas.pending, (state) => {
        state.loading = "loading";
      })
      // fetching options
      .addCase(fetchClubOptions.fulfilled, (state, action) => {
        state.clubOptions = action.payload.options.items;
        state.loading = "idle";
      })
      .addCase(fetchClubOptions.rejected, (state) => {
        state.loading = "failed";
      })
      .addCase(fetchClubOptions.pending, (state) => {
        state.loading = "loading";
      })
      // fetching address
      .addCase(fetchClubAddress.fulfilled, (state, action) => {
        state.clubAddress = action.payload.address;
        state.loading = "idle";
      })
      .addCase(fetchClubAddress.rejected, (state) => {
        state.loading = "failed";
      })
      .addCase(fetchClubAddress.pending, (state) => {
        state.loading = "loading";
      })
      // fetching club photos
      .addCase(fetchClubPhotos.fulfilled, (state, action) => {
        state.clubPhotos = action.payload.photos.items;
        state.loading = "idle";
      })
      .addCase(fetchClubPhotos.rejected, (state) => {
        state.loading = "failed";
      })
      .addCase(fetchClubPhotos.pending, (state) => {
        state.loading = "loading";
      })
      // edit club photos
      .addCase(editClubPhotos.fulfilled, (state, action) => {
        state.clubPhotos = action.meta.arg.photos;
        state.loading = "idle";
      })
      .addCase(editClubPhotos.rejected, (state) => {
        state.loading = "failed";
      })
      .addCase(editClubPhotos.pending, (state) => {
        state.loading = "loading";
      })
      // edit club
      .addCase(editClub.fulfilled, (state, action) => {
        const editedClub: PartialBy<IClubEditRequest, "code"> = action.meta.arg;
        delete editedClub.code;
        state.clubDetail = editedClub;
        state.loading = "idle";
      })
      .addCase(editClub.rejected, (state) => {
        state.loading = "failed";
      })
      .addCase(editClub.pending, (state) => {
        state.loading = "loading";
      })
      // fetching all areas (rooms)
      .addCase(fetchAllAreas.fulfilled, (state, action) => {
        state.clubAllAreas = action.payload.areas.items;
        state.loading = "idle";
      })
      .addCase(fetchAllAreas.rejected, (state) => {
        state.loading = "failed";
      })
      .addCase(fetchAllAreas.pending, (state) => {
        state.loading = "loading";
      })
      // create area for club
      .addCase(createClubArea.fulfilled, (state, action) => {
        const createdArea: IClubAreasItem = {
          code: action.payload.code,
          name: action.meta.arg.name,
        };
        state.clubAreas?.push(createdArea);
        state.loading = "idle";
      })
      .addCase(createClubArea.rejected, (state) => {
        state.loading = "failed";
      })
      .addCase(createClubArea.pending, (state) => {
        state.loading = "loading";
      })
      // delete room
      .addCase(deleteArea.fulfilled, (state, action) => {
        if (state.clubAreas) {
          const index = state.clubAreas.findIndex(
            (item) => item.code === action.meta.arg
          );
          state.clubAreas.splice(index, 1);
        }
        state.loading = "idle";
      })
      .addCase(deleteArea.rejected, (state) => {
        state.loading = "failed";
      })
      .addCase(deleteArea.pending, (state) => {
        state.loading = "loading";
      })
      // edit room
      .addCase(editArea.fulfilled, (state, action) => {
        if (state.clubAreas) {
          const index = state.clubAreas.findIndex(
            (item) => item.code === action.meta.arg.code
          );
          state.clubAreas[index].name = action.meta.arg.name;
        }
        state.loading = "idle";
      })
      .addCase(editArea.rejected, (state) => {
        state.loading = "failed";
      })
      .addCase(editArea.pending, (state) => {
        state.loading = "loading";
      })
      // add option
      .addCase(createClubOption.fulfilled, (state, action) => {
        const newOption = {
          code: action.payload.code,
          icon: action.meta.arg.icon,
          name: action.meta.arg.name,
        };
        state.clubOptions?.push(newOption);
        state.loading = "idle";
      })
      .addCase(createClubOption.rejected, (state) => {
        state.loading = "failed";
      })
      .addCase(createClubOption.pending, (state) => {
        state.loading = "loading";
      })
      // edit option
      .addCase(editOption.fulfilled, (state, action) => {
        if (state.clubOptions) {
          const index = state.clubOptions.findIndex(
            (item) => item.code === action.meta.arg.code
          );
          state.clubOptions[index].name = action.meta.arg.name;
          state.clubOptions[index].icon = action.meta.arg.icon;
        }
        state.loading = "idle";
      })
      .addCase(editOption.rejected, (state) => {
        state.loading = "failed";
      })
      .addCase(editOption.pending, (state) => {
        state.loading = "loading";
      })
      // delete option
      .addCase(deleteOption.fulfilled, (state, action) => {
        if (state.clubOptions) {
          const index = state.clubOptions.findIndex(
            (item) => item.code === action.meta.arg
          );
          state.clubOptions.splice(index, 1);
        }
        state.loading = "idle";
      })
      .addCase(deleteOption.rejected, (state) => {
        state.loading = "failed";
      })
      .addCase(deleteOption.pending, (state) => {
        state.loading = "loading";
      })
      // edit club address
      .addCase(editAddress.fulfilled, (state, action) => {
        const newAddress = {
          ...action.meta.arg,
        };
        state.clubAddress = newAddress;
        state.loading = "idle";
      })
      .addCase(editAddress.rejected, (state) => {
        state.loading = "failed";
      })
      .addCase(editAddress.pending, (state) => {
        state.loading = "loading";
      });
  },
});

export const { actions: clubActions, reducer: clubReducer } = clubsSlice;
