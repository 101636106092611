import { Button, Card, Form, Input, InputNumber, PageHeader } from "antd";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

import { ShowErrorMessages, errorActions } from "@/entities/error";
import * as form from "@/constants/formsWrappers";
import { useAppDispatch } from "@/app";
import frontendPaths from "@/routes/paths";
import { IProgrammEditValues, createProgramm } from "@/entities/programms";

const { TextArea } = Input;

function ProgrammCreate() {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const [isCreated, setIsCreated] = useState(false);

  useEffect(() => {
    void dispatch(errorActions.resetErrors());
  }, []);

  const handleProgrammCreate = (values: IProgrammEditValues) => {
    void dispatch(createProgramm(values)).then((res) => {
      if (res.meta.requestStatus === "fulfilled") setIsCreated(true);
    });
  };

  return (
    <>
      <PageHeader
        title={frontendPaths.programm_create.title}
        onBack={() => navigate(-1)}
      />
      <Card>
        <ShowErrorMessages />
        <Form
          name="createProgramm"
          labelCol={form.LebelColWide}
          wrapperCol={form.WrapperColWide}
          autoComplete="off"
          onFinish={handleProgrammCreate}
          disabled={isCreated}
        >
          <Form.Item
            label="Название программы"
            name="name"
            rules={[
              {
                required: true,
                message: "Пожалуйста укажите название программы",
              },
            ]}
          >
            <Input />
          </Form.Item>

          <Form.Item
            label="Продолжительность, мин"
            name="duration"
            rules={[
              {
                required: true,
                message: "Пожалуйста укажите продолжительность программы",
              },
            ]}
          >
            <InputNumber />
          </Form.Item>

          <Form.Item
            label="Калорий"
            name="calories"
            rules={[
              {
                required: true,
                message: "Пожалуйста укажите количество калорий",
              },
            ]}
          >
            <InputNumber />
          </Form.Item>

          <Form.Item
            label="Краткое описание"
            name="description"
            rules={[
              {
                required: true,
                message: "Пожалуйста укажите краткое описание программы",
              },
            ]}
          >
            <TextArea rows={4} showCount maxLength={100} />
          </Form.Item>

          <Form.Item wrapperCol={form.SubmitCol}>
            <Button type="primary" htmlType="submit">
              Сохранить
            </Button>
          </Form.Item>
        </Form>
      </Card>
    </>
  );
}

export default ProgrammCreate;
