import { Card, PageHeader, Pagination, Table, Tag } from "antd";
import { ColumnProps } from "antd/lib/table";
import { useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";

import { ShowErrorMessages, errorActions } from "@/entities/error";
import TooltipDate from "@/components/TooltipDate";
import { useAppDispatch, useAppSelector } from "@/app";
import frontendPaths from "@/routes/paths";
import addReactKey from "@/shared/utils/addReactKey";
import {
  IFormQueriesListItem,
  fetchformQueries,
  formQueriesSelectors,
} from "@/entities/formQueries";

function FormQueries() {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  useEffect(() => {
    void dispatch(errorActions.resetErrors());
    void dispatch(fetchformQueries({ page: 1 }));
  }, []);

  const formQueriesData = useAppSelector(({ formQueries }) => formQueries);
  const { loading, pagination } = formQueriesData;
  const orders = addReactKey<IFormQueriesListItem>(
    useAppSelector(formQueriesSelectors.selectAll)
  );

  const onPageChange = (page: number) => {
    void dispatch(fetchformQueries({ page }));
  };

  const columns: ColumnProps<IFormQueriesListItem>[] = [
    {
      title: "Дата создания",
      dataIndex: "createdAt",
      key: "createdAt",
      render: (_, record) => (
        <Link to={frontendPaths.form_querie.URL(record.code)}>
          <TooltipDate date={record.createdAt} />
        </Link>
      ),
    },
    {
      title: "Имя",
      dataIndex: "name",
      key: "name",
      render: (_, record) => `${record.name}`,
    },
    {
      title: "type",
      dataIndex: "type",
      key: "type",
      render: (_, record) => <Tag>{record.type}</Tag>,
    },
    {
      title: "club",
      dataIndex: "club",
      key: "club",
      responsive: ["xxl", "xl", "lg", "md"],
      render: (_, record) => record.club?.name,
    },
  ];

  return (
    <>
      <PageHeader
        title={frontendPaths.form_queries.title}
        onBack={() => navigate(-1)}
      />
      <Card>
        <ShowErrorMessages />
        <Table
          dataSource={orders}
          columns={columns}
          loading={loading === "loading"}
          pagination={false}
        />
        {pagination && (
          <Pagination
            current={pagination.currentPage}
            pageSize={pagination.pageSize}
            total={pagination.total}
            showSizeChanger={false}
            onChange={onPageChange}
          />
        )}
      </Card>
    </>
  );
}

export default FormQueries;
