import {
  Button,
  Card,
  DatePicker,
  Form,
  Input,
  InputNumber,
  PageHeader,
  Radio,
  Select,
  Switch,
} from "antd";
import dayjs from "dayjs";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

import { ShowErrorMessages, errorActions } from "@/entities/error";
import * as form from "@/constants/formsWrappers";
import { promocodeRegExp } from "@/constants/params";
import { useAppDispatch, useAppSelector } from "@/app";
import frontendPaths from "@/routes/paths";
import { fetchAllProducts } from "@/entities/products";
import {
  IPromocodeCreateRequest,
  IPromocodeCreateValues,
} from "@/entities/promocodes/model/types/promocodes";
import { createPromocode } from "@/entities/promocodes";

const { TextArea } = Input;

function PromocodeCreate() {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const [isCreated, setIsCreated] = useState(false);

  useEffect(() => {
    void dispatch(errorActions.resetErrors());
    void dispatch(fetchAllProducts());
  }, []);

  const { allProducts } = useAppSelector(({ products }) => products);
  const productsOptions = allProducts?.map((item) => ({
    value: item.code,
    label: item.title,
  }));

  const handleProgrammCreate = (values: IPromocodeCreateValues) => {
    const request: IPromocodeCreateRequest = {
      active: values.active,
      secret: values.secret,
      type: values.type,
      discount: values.discount,
      note: values.note,
      startAt: dayjs(values.startAt).format("YYYY-MM-DD"),
      endAt: dayjs(values.endAt).format("YYYY-MM-DD"),
      productCodes: values.productCodes,
    };
    void dispatch(createPromocode(request)).then((res) => {
      if (res.meta.requestStatus === "fulfilled") setIsCreated(true);
    });
  };

  return (
    <>
      <PageHeader
        title={frontendPaths.promocode_create.title}
        onBack={() => navigate(-1)}
      />
      <Card>
        <ShowErrorMessages />
        <Form
          name="createProgramm"
          labelCol={form.LebelColWide}
          wrapperCol={form.WrapperColWide}
          autoComplete="off"
          onFinish={handleProgrammCreate}
          disabled={isCreated}
        >
          <Form.Item
            label="Активен"
            name="active"
            valuePropName="checked"
            initialValue
          >
            <Switch />
          </Form.Item>

          <Form.Item
            label="Промокод"
            name="secret"
            rules={[
              {
                required: true,
                message: "Пожалуйста укажите промокод",
              },
              {
                required: true,
                pattern: new RegExp(promocodeRegExp),
                message:
                  "Допускаются только латинские или русские буквы, цифры и дефис",
              },
            ]}
          >
            <Input />
          </Form.Item>

          <Form.Item
            label="Тип скидки"
            name="type"
            rules={[
              {
                required: true,
                message: "Пожалуйста выберите тип скидки",
              },
            ]}
          >
            <Radio.Group>
              <Radio value="absolute">в рублях</Radio>
              <Radio value="percent">в процентах</Radio>
            </Radio.Group>
          </Form.Item>

          <Form.Item
            label="Скидка"
            name="discount"
            rules={[
              {
                required: true,
                message: "Пожалуйста укажите скидку в рублях",
              },
            ]}
          >
            <InputNumber precision={2} stringMode />
          </Form.Item>

          <Form.Item
            label="Примечание"
            name="note"
            rules={[
              {
                required: true,
                message: "Пожалуйста укажите примечание",
              },
            ]}
          >
            <TextArea rows={4} />
          </Form.Item>

          <Form.Item
            label="Действует от"
            name="startAt"
            rules={[
              {
                required: true,
                message: "Пожалуйста укажите дату начала",
              },
            ]}
          >
            <DatePicker />
          </Form.Item>

          <Form.Item
            label="Действует до"
            name="endAt"
            rules={[
              {
                required: true,
                message: "Пожалуйста укажите дату окончания",
              },
            ]}
          >
            <DatePicker />
          </Form.Item>

          <Form.Item
            label="Акции"
            name="productCodes"
            rules={[
              {
                required: true,
                message: "Пожалуйста выберите акцию из списка",
              },
            ]}
          >
            <Select
              options={productsOptions}
              mode="multiple"
              showSearch
              optionFilterProp="children"
              placeholder="Введите название акции"
              filterOption={(input, option) =>
                (option?.label ?? "").includes(input)
              }
              filterSort={(optionA, optionB) =>
                (optionA?.label ?? "")
                  .toLowerCase()
                  .localeCompare((optionB?.label ?? "").toLowerCase())
              }
            />
          </Form.Item>

          <Form.Item wrapperCol={form.SubmitCol}>
            <Button type="primary" htmlType="submit">
              Сохранить
            </Button>
          </Form.Item>
        </Form>
      </Card>
    </>
  );
}

export default PromocodeCreate;
