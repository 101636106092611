import {
  Button,
  Card,
  Descriptions,
  PageHeader,
  Space,
  Table,
  Typography,
} from "antd";
import { ColumnProps } from "antd/lib/table";
import { useEffect, useState } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";

import DeleteConfirmModal from "@/components/modals/DeleteConfirmModal";
import OptionAddModal from "@/components/modals/OptionAddModal";
import OptionEditModal from "@/components/modals/OptionEditModal";
import RoomAddModal from "@/components/modals/RoomAddModal";
import RoomEditModal from "@/components/modals/RoomEditModal";
import Preloader from "@/components/Preloader";
import { ShowErrorMessages, errorActions } from "@/entities/error";
import TableActionsMenu from "@/components/TableActionsMenu";
import { useAppDispatch, useAppSelector } from "@/app";
import { IClubAreasItem } from "@/entities/club/model/types/rooms";
import frontendPaths from "@/routes/paths";
import addReactKey from "@/shared/utils/addReactKey";
import {
  createClubArea,
  createClubOption,
  deleteArea,
  deleteOption,
  editArea,
  editOption,
  fetchClub,
  fetchClubAddress,
  fetchClubAreas,
  fetchClubOptions,
  getClubAddress,
  getClubAreas,
  getClubDetail,
  getClubLoading,
  getClubOptions,
  IClubOptionsItem,
} from "@/entities/club";

import PageNotFound from "./PageNotFound";

const { Title, Paragraph } = Typography;

function ClientPage() {
  const param = useParams();
  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  const [isAddRoomMode, setAddRoomMode] = useState(false);
  const [isEditRoomMode, setEditRoomMode] = useState(false);
  const [editableArea, setEditableArea] = useState<IClubAreasItem | null>(null);
  const [isDeleteMode, setDeleteMode] = useState(false);
  const [isAddOptionMode, setAddOptionMode] = useState(false);
  const [isEditOptionMode, setEditOptionMode] = useState(false);
  const [editableOption, setEditableOption] = useState<IClubOptionsItem | null>(
    null
  );
  const [isDeleteOptionMode, setDeleteOptionMode] = useState(false);

  useEffect(() => {
    dispatch(errorActions.resetErrors());
    if (param.id) {
      void dispatch(fetchClub(param.id));
      void dispatch(fetchClubAreas(param.id));
      void dispatch(fetchClubOptions(param.id));
      void dispatch(fetchClubAddress(param.id));
    }
  }, []);

  const clubDetail = useAppSelector(getClubDetail);
  const loading = useAppSelector(getClubLoading);
  const clubAddress = useAppSelector(getClubAddress);
  const clubAreas = useAppSelector(getClubAreas);
  const clubOptions = useAppSelector(getClubOptions);

  const areas = clubAreas ? addReactKey(clubAreas) : undefined;
  const options = clubOptions ? addReactKey(clubOptions) : undefined;

  // add room functions
  const handleTurnOnAddRoomMode = () => {
    setAddRoomMode(true);
  };

  const handleSubmitAddRoom = (values: { name: string }) => {
    if (param.id) {
      const request = {
        clubCode: param.id,
        name: values.name,
      };
      void dispatch(createClubArea(request));
    }
    setAddRoomMode(false);
  };

  const handleCancelAddRoom = () => {
    setAddRoomMode(false);
  };

  // edit room functions
  const handleTurnOnEditRoomMode = (code: string) => {
    const editedArea = clubAreas?.find((item) => item.code === code);
    if (editedArea) {
      setEditRoomMode(true);
      setEditableArea(editedArea);
    }
  };

  const handleSubmitEditRoom = (values: { name: string }) => {
    if (editableArea) {
      const request = {
        code: editableArea?.code,
        name: values.name,
      };
      void dispatch(editArea(request));
    }
    setEditableArea(null);
    setEditRoomMode(false);
  };

  const handleCancelEditRoom = () => {
    setEditableArea(null);
    setEditRoomMode(false);
  };

  // delete room
  const handleTurnOnDeleteRoomMode = (code: string) => {
    const deletedArea = clubAreas?.find((item) => item.code === code);
    if (deletedArea) {
      setDeleteMode(true);
      setEditableArea(deletedArea);
    }
  };

  const handleDeleteRoom = (code: string) => {
    void dispatch(deleteArea(code));
    setDeleteMode(false);
  };

  const handleCancelDelete = () => {
    setDeleteMode(false);
  };

  // add option functions
  const handleTurnOnAddOptionMode = () => {
    setAddOptionMode(true);
  };

  const handleSubmitAddOption = (values: { name: string; icon: string }) => {
    if (param.id) {
      const request = {
        clubCode: param.id,
        name: values.name,
        icon: values.icon,
      };
      void dispatch(createClubOption(request));
    }
    setAddOptionMode(false);
  };

  const handleCancelAddOption = () => {
    setAddOptionMode(false);
  };

  // edit option
  const handleTurnOnEditOptionMode = (code: string) => {
    const editedOption = clubOptions?.find((item) => item.code === code);
    if (editedOption) {
      setEditOptionMode(true);
      setEditableOption(editedOption);
    }
  };

  const handleSubmitEditOption = (values: { name: string; icon: string }) => {
    if (editableOption) {
      const request = {
        code: editableOption?.code,
        name: values.name,
        icon: values.icon,
      };
      void dispatch(editOption(request));
    }
    setEditableOption(null);
    setEditOptionMode(false);
  };

  const handleCancelEditOption = () => {
    setEditableOption(null);
    setEditOptionMode(false);
  };

  // delete option
  const handleTurnOnDeleteOptionMode = (code: string) => {
    const deletedOption = clubOptions?.find((item) => item.code === code);
    if (deletedOption) {
      setEditableOption(deletedOption);
      setDeleteOptionMode(true);
    }
  };

  const handleDeleteOption = (code: string) => {
    void dispatch(deleteOption(code));
    setDeleteOptionMode(false);
  };

  const handleCancelDeleteOption = () => {
    setDeleteOptionMode(false);
  };

  const columnsRooms: ColumnProps<IClubAreasItem>[] = [
    {
      title: "Имя зала",
      dataIndex: "name",
      key: "name",
    },
    {
      title: "Действие",
      dataIndex: "action",
      key: "action",
      align: "right",
      render: (_, record) => (
        <TableActionsMenu
          actions={[
            {
              title: "Редактировать",
              url: null,
              action: () => handleTurnOnEditRoomMode(record.code),
            },
            {
              title: "Удалить",
              url: null,
              action: () => handleTurnOnDeleteRoomMode(record.code),
            },
          ]}
        />
      ),
    },
  ];

  const columnsOptions: ColumnProps<IClubOptionsItem>[] = [
    {
      title: "Имя опции",
      dataIndex: "name",
      key: "name",
    },
    {
      title: "Иконка",
      dataIndex: "icon",
      key: "icon",
    },
    {
      title: "Действие",
      dataIndex: "action",
      key: "action",
      align: "right",
      render: (_, record) => (
        <TableActionsMenu
          actions={[
            {
              title: "Редактировать",
              url: null,
              action: () => handleTurnOnEditOptionMode(record.code),
            },
            {
              title: "Удалить",
              url: null,
              action: () => handleTurnOnDeleteOptionMode(record.code),
            },
          ]}
        />
      ),
    },
  ];

  if (!param.id || loading === "failed") {
    return <PageNotFound />;
  }

  if (!clubDetail) {
    return <Preloader />;
  }

  return (
    <>
      <PageHeader
        title={`${frontendPaths.club.title}: ${clubDetail?.clubName}`}
        onBack={() => navigate(-1)}
      />
      <Space direction="vertical">
        <Card
          title={<Title level={5}>Параметры клуба</Title>}
          extra={
            <Space>
              <Link to={frontendPaths.club_edit.URL(param.id)}>
                <Button type="primary">{frontendPaths.club_edit.title}</Button>
              </Link>
              <Link to={frontendPaths.club_address_edit.URL(param.id)}>
                <Button type="primary">
                  {frontendPaths.club_address_edit.title}
                </Button>
              </Link>
              <Link to={frontendPaths.club_photos_edit.URL(param.id)}>
                <Button type="primary">
                  {frontendPaths.club_photos_edit.title}
                </Button>
              </Link>
            </Space>
          }
        >
          <ShowErrorMessages />
          <Descriptions bordered>
            <Descriptions.Item label="Сокращенное название">
              {clubDetail?.clubName}
            </Descriptions.Item>
            <Descriptions.Item label="Полное название">
              {clubDetail?.title}
            </Descriptions.Item>
            <Descriptions.Item label="Телефон">
              {clubDetail?.phone}
            </Descriptions.Item>
            <Descriptions.Item label="Режим работы">
              {clubDetail?.timetable}
            </Descriptions.Item>
            <Descriptions.Item label="Примечание к режиму работы">
              {clubDetail?.timetableNote}
            </Descriptions.Item>
            <Descriptions.Item label="Адрес">
              Город: {clubAddress?.city}
              <br />
              {clubAddress?.streetType}: {clubAddress?.street}, дом:{" "}
              {clubAddress?.house}
              <br />
              вход: {clubAddress?.entrance}
              <br />
              координаты: [{clubAddress?.longtitude}, {clubAddress?.latitude}]
            </Descriptions.Item>
            <Descriptions.Item label="Описание">
              <Paragraph
                ellipsis={{
                  rows: 2,
                  expandable: true,
                }}
              >
                {clubDetail?.description}
              </Paragraph>
            </Descriptions.Item>
          </Descriptions>
        </Card>
        <Card
          title={<Title level={5}>Залы клуба</Title>}
          extra={
            <Button type="primary" onClick={handleTurnOnAddRoomMode}>
              Добавить
            </Button>
          }
        >
          <Table dataSource={areas} columns={columnsRooms} pagination={false} />
        </Card>
        <Card
          title={<Title level={5}>Опции клуба</Title>}
          extra={
            <Button type="primary" onClick={handleTurnOnAddOptionMode}>
              Добавить
            </Button>
          }
        >
          <Table
            dataSource={options}
            columns={columnsOptions}
            pagination={false}
          />
        </Card>
      </Space>

      <RoomAddModal
        isOpen={isAddRoomMode}
        onOk={handleSubmitAddRoom}
        onCancel={handleCancelAddRoom}
      />
      {editableArea && (
        <RoomEditModal
          isOpen={isEditRoomMode}
          onOk={handleSubmitEditRoom}
          onCancel={handleCancelEditRoom}
          area={editableArea}
        />
      )}
      {editableArea && (
        <DeleteConfirmModal
          isOpen={isDeleteMode}
          onOk={() => handleDeleteRoom(editableArea.code)}
          onCancel={handleCancelDelete}
          text="зал"
        />
      )}
      <OptionAddModal
        isOpen={isAddOptionMode}
        onOk={handleSubmitAddOption}
        onCancel={handleCancelAddOption}
      />
      {editableOption && (
        <OptionEditModal
          isOpen={isEditOptionMode}
          onOk={handleSubmitEditOption}
          onCancel={handleCancelEditOption}
          option={editableOption}
        />
      )}
      {editableOption && (
        <DeleteConfirmModal
          isOpen={isDeleteOptionMode}
          onOk={() => handleDeleteOption(editableOption.code)}
          onCancel={handleCancelDeleteOption}
          text="опцию?"
        />
      )}
    </>
  );
}

export default ClientPage;
