import { Button, Card, Form, Input, PageHeader, Space } from "antd";
import { useEffect } from "react";
import { useNavigate } from "react-router-dom";

import { useAppDispatch, useAppSelector } from "@/app";
import { getAccessToken, getAuthIsLoading, login } from "@/entities/auth";
import { ShowErrorMessages, errorActions } from "@/entities/error";

import * as form from "../constants/formsWrappers";
import LayoutTemplate from "../layouts/PublicLayoutTemplate";
import frontendPaths from "../routes/paths";

type loginFormValues = {
  username: string;
  password: string;
};

function Authorization() {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const loading = useAppSelector(getAuthIsLoading);
  const accessToken = useAppSelector(getAccessToken);

  useEffect(() => {
    void dispatch(errorActions.resetErrors());
  }, []);

  useEffect(() => {
    if (loading === "idle" && accessToken !== null) {
      navigate(frontendPaths.main.URL(), { replace: true });
    }
  }, [loading, accessToken]);

  const onFinish = (values: loginFormValues) => {
    const request = {
      username: values.username,
      password: values.password,
    };
    void dispatch(errorActions.resetErrors());
    void dispatch(login(request));
  };

  return (
    <LayoutTemplate>
      <PageHeader title="Авторизация" />
      <Card>
        <ShowErrorMessages />
        <Space
          direction="vertical"
          size="middle"
          style={{
            display: "flex",
          }}
        >
          <Form
            name="authorization"
            labelCol={form.LebelCol}
            wrapperCol={form.WrapperCol}
            onFinish={onFinish}
            autoComplete="off"
            disabled={loading === "loading"}
          >
            <Form.Item
              label="Имя пользователя"
              name="username"
              rules={[
                {
                  required: true,
                  message: "Пожалуйста укажите имя пользователя",
                },
              ]}
            >
              <Input />
            </Form.Item>
            <Form.Item
              label="Пароль"
              name="password"
              rules={[
                {
                  required: true,
                  message: "Пожалуйста укажите пароль",
                },
              ]}
            >
              <Input.Password />
            </Form.Item>
            <Form.Item wrapperCol={form.SubmitCol}>
              <Button type="primary" htmlType="submit">
                Войти
              </Button>
            </Form.Item>
          </Form>
        </Space>
      </Card>
    </LayoutTemplate>
  );
}

export default Authorization;
