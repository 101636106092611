import { Provider } from "react-redux";
import { ReactElement } from "react";

import setupAxios from "@/api/axios";

import { store } from "./store";

interface IProps {
  children: ReactElement;
}

setupAxios(store);

export function StoreProvider(props: IProps) {
  const { children } = props;
  return <Provider store={store}>{children}</Provider>;
}
