import { Button, Card, Form, Input, PageHeader } from "antd";
import { useNavigate } from "react-router-dom";
import { useEffect, useState } from "react";

import { ShowErrorMessages, errorActions } from "@/entities/error";
import * as form from "@/constants/formsWrappers";
import { useAppDispatch } from "@/app";
import frontendPaths from "@/routes/paths";
import { ISliderEditValues, createSlider } from "@/entities/sliders";

function SliderCreate() {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const [isCreated, setIsCreated] = useState(false);

  useEffect(() => {
    void dispatch(errorActions.resetErrors());
  }, []);

  const handleSliderCreate = (values: ISliderEditValues) => {
    void dispatch(createSlider(values)).then((res) => {
      if (res.meta.requestStatus === "fulfilled") setIsCreated(true);
    });
  };

  return (
    <>
      <PageHeader
        title={frontendPaths.slider_create.title}
        onBack={() => navigate(-1)}
      />
      <Card>
        <ShowErrorMessages />
        <Form
          name="createSlider"
          labelCol={form.LebelColWide}
          wrapperCol={form.WrapperColWide}
          autoComplete="off"
          onFinish={handleSliderCreate}
          disabled={isCreated}
        >
          <Form.Item
            label="Заголовок"
            name="title"
            rules={[
              {
                required: true,
                message: "Пожалуйста укажите заголовок слайдера",
              },
            ]}
          >
            <Input />
          </Form.Item>

          <Form.Item
            label="Подзаголовок"
            name="subTitle"
            rules={[
              {
                required: true,
                message: "Пожалуйста укажите подзаголовок слайдера",
              },
            ]}
          >
            <Input />
          </Form.Item>

          <Form.Item
            label="Название кнопки"
            name="buttonTitle"
            rules={[
              {
                required: true,
                message: "Пожалуйста укажите название кнопки",
              },
            ]}
          >
            <Input />
          </Form.Item>

          <Form.Item
            label="URL кнопки"
            name="buttonLink"
            extra="Для формы 'отправить заявку' укажите #send-request"
            rules={[
              {
                required: true,
                message: "Пожалуйста укажите URL кнопки",
              },
            ]}
          >
            <Input />
          </Form.Item>

          <Form.Item wrapperCol={form.SubmitCol}>
            <Button type="primary" htmlType="submit">
              Сохранить
            </Button>
          </Form.Item>
        </Form>
      </Card>
    </>
  );
}

export default SliderCreate;
