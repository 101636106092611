import { createAsyncThunk } from "@reduxjs/toolkit";
import axios, { AxiosError } from "axios";

import backendPaths from "@/constants/backendPaths";
import { IThunkConfig } from "@/app";

import { IClubsOptionsResponse } from "../types/clubs";

export const fetchClubOptions = createAsyncThunk<
  IClubsOptionsResponse,
  string,
  IThunkConfig
>("clubs/fetchClubOptions", async (clubCode, thunkAPI) => {
  const { rejectWithValue } = thunkAPI;
  try {
    const response = await axios.get(backendPaths.CLUB_OPTIONS_URL(clubCode));
    return response.data as IClubsOptionsResponse;
  } catch (err) {
    if (err instanceof AxiosError) return rejectWithValue(err.response?.data);
    throw err;
  }
});
