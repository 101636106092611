import {
  Button,
  Card,
  DatePicker,
  Form,
  Input,
  PageHeader,
  Upload,
} from "antd";
import dayjs from "dayjs";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

import { ShowErrorMessages, errorActions } from "@/entities/error";
import TextEditor from "@/components/TextEditor";
import * as form from "@/constants/formsWrappers";
import { useAppDispatch } from "@/app";
import { convertIFileResponseToPhotoListItem } from "@/shared/models/files";
import frontendPaths from "@/routes/paths";
import customUpload, { normFile } from "@/shared/utils/customUpload";
import { INewsCreateValues } from "@/entities/news/model/types/news";
import { createNews } from "@/entities/news";

function NewsCreate() {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const [isCreated, setIsCreated] = useState(false);

  useEffect(() => {
    void dispatch(errorActions.resetErrors());
  }, []);

  const handleNewsCreate = (values: INewsCreateValues) => {
    if (!values.photo || !values.photo[0]?.response?.code) return;

    const request = {
      title: values.title,
      date: dayjs(values.date).format("YYYY-MM-DD HH:mm:ss"),
      content: values.content,
      photo: convertIFileResponseToPhotoListItem(values.photo[0].response),
    };
    void dispatch(createNews(request)).then((res) => {
      if (res.meta.requestStatus === "fulfilled") setIsCreated(true);
    });
  };

  return (
    <>
      <PageHeader
        title={frontendPaths.news_create.title}
        onBack={() => navigate(-1)}
      />
      <Card>
        <ShowErrorMessages />
        <Form
          name="createProgramm"
          labelCol={form.LebelColWide}
          wrapperCol={form.WrapperColWide}
          autoComplete="off"
          onFinish={handleNewsCreate}
          disabled={isCreated}
        >
          <Form.Item
            label="Заголовок новости"
            name="title"
            rules={[
              {
                required: true,
                message: "Пожалуйста укажите заголовок новости",
              },
            ]}
          >
            <Input />
          </Form.Item>

          <Form.Item
            label="Дата и время"
            name="date"
            rules={[
              {
                required: true,
                message: "Пожалуйста укажите дату и время новости",
              },
            ]}
          >
            <DatePicker
              showTime={{
                format: "HH:mm",
              }}
            />
          </Form.Item>

          <Form.Item
            label="Текст новости"
            name="content"
            rules={[
              {
                required: true,
                message: "Пожалуйста укажите текст новости",
              },
            ]}
          >
            <TextEditor value="content" readonly={isCreated} />
          </Form.Item>

          <Form.Item
            label="Фото"
            name="photo"
            valuePropName="fileList"
            getValueFromEvent={normFile}
            rules={[
              {
                required: true,
                message: "Пожалуйста укажите фото тренера",
              },
            ]}
          >
            <Upload
              accept="image/*"
              customRequest={customUpload}
              listType="picture-card"
              multiple={false}
              maxCount={1}
            >
              <Button type="link">Загрузить</Button>
            </Upload>
          </Form.Item>

          <Form.Item wrapperCol={form.SubmitCol}>
            <Button type="primary" htmlType="submit">
              Сохранить
            </Button>
          </Form.Item>
        </Form>
      </Card>
    </>
  );
}

export default NewsCreate;
