import { Button, Card, Form, Input, PageHeader } from "antd";
import { useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";

import { ShowErrorMessages, errorActions } from "@/entities/error";
import TextEditor from "@/components/TextEditor";
import * as form from "@/constants/formsWrappers";
import { emailRegExp } from "@/constants/params";
import { useAppDispatch, useAppSelector } from "@/app";
import frontendPaths from "@/routes/paths";
import { IClubEditValues } from "@/entities/club/model/types/clubs";
import {
  editClub,
  fetchClub,
  getClubDetail,
  getClubLoading,
} from "@/entities/club";

import PageNotFound from "./PageNotFound";

const { TextArea } = Input;

function ClubEdit() {
  const param = useParams();
  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  useEffect(() => {
    void dispatch(errorActions.resetErrors());
    if (param.id) {
      void dispatch(fetchClub(param.id));
    }
  }, []);

  const clubDetail = useAppSelector(getClubDetail);
  const loading = useAppSelector(getClubLoading);

  const handleUpdateClub = (values: IClubEditValues) => {
    if (param.id) {
      const request = {
        code: param.id,
        ...values,
      };
      void dispatch(editClub(request));
    }
  };

  if (!param.id) {
    return <PageNotFound />;
  }

  return clubDetail ? (
    <>
      <PageHeader
        title={`${frontendPaths.club_edit.title}: ${clubDetail.clubName}`}
        onBack={() => navigate(-1)}
      />
      <Card>
        <ShowErrorMessages />
        <Form
          name="editClub"
          labelCol={form.LebelColWide}
          wrapperCol={form.WrapperColWide}
          autoComplete="off"
          disabled={loading === "loading"}
          initialValues={{
            clubName: clubDetail.clubName,
            title: clubDetail.title,
            timetable: clubDetail.timetable,
            timetableNote: clubDetail.timetableNote,
            phone: clubDetail.phone,
            email: clubDetail.email,
            description: clubDetail.description,
          }}
          onFinish={handleUpdateClub}
        >
          <Form.Item
            label="Наименование клуба"
            name="clubName"
            rules={[
              {
                required: true,
                message: "Пожалуйста укажите наименование клуба",
              },
            ]}
          >
            <Input />
          </Form.Item>

          <Form.Item
            label="Title"
            name="title"
            rules={[
              {
                required: true,
                message: "Пожалуйста укажите title клуба",
              },
            ]}
          >
            <Input />
          </Form.Item>

          <Form.Item
            label="Режим работы"
            name="timetable"
            tooltip="Учитываются переводы строк"
            rules={[
              {
                required: true,
                message: "Пожалуйста укажите режим работы клуба",
              },
            ]}
          >
            <TextArea rows={7} />
          </Form.Item>

          <Form.Item
            label="Примечание к режиму работы"
            name="timetableNote"
            rules={[
              {
                required: true,
                message: "Пожалуйста укажите примечание к режиму работы клуба",
              },
            ]}
          >
            <Input />
          </Form.Item>

          <Form.Item
            label="Телефон"
            name="phone"
            rules={[
              {
                required: true,
                message: "Пожалуйста укажите телефон клуба",
              },
            ]}
          >
            <Input />
          </Form.Item>

          <Form.Item
            label="Email для уведомлений"
            name="email"
            rules={[
              {
                required: true,
                message:
                  "Пожалуйста укажите email для получения уведомлений по заказам на этот клуб",
              },
              {
                required: true,
                pattern: new RegExp(emailRegExp),
                message: "Пожалуйста укажите валидный email",
              },
            ]}
          >
            <Input />
          </Form.Item>

          <Form.Item
            label="Описание"
            name="description"
            rules={[
              {
                required: true,
                message: "Пожалуйста укажите описание клуба",
              },
            ]}
          >
            <TextEditor value="description" />
          </Form.Item>

          <Form.Item wrapperCol={form.SubmitCol}>
            <Button type="primary" htmlType="submit">
              Сохранить
            </Button>
          </Form.Item>
        </Form>
      </Card>
    </>
  ) : null;
}

export default ClubEdit;
