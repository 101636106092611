import { Card, Descriptions, Divider, PageHeader, Tag } from "antd";
import dayjs from "dayjs";
import { useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";

import PaymentStatus from "@/components/PaymentStatus";
import { ShowErrorMessages, errorActions } from "@/entities/error";
import { dateFormatDate, dateFormatFull } from "@/constants/params";
import { useAppDispatch, useAppSelector } from "@/app";
import frontendPaths from "@/routes/paths";
import getFullName from "@/shared/utils/getFullName";
import { fetchOrder } from "@/entities/orders";
import Preloader from "@/components/Preloader";

function OrderPage() {
  const navigate = useNavigate();
  const param = useParams();
  const dispatch = useAppDispatch();

  useEffect(() => {
    void dispatch(errorActions.resetErrors());
    param.id && dispatch(fetchOrder(param.id));
  }, []);

  const ordersData = useAppSelector(({ orders }) => orders);
  const { loading, orderDetail } = ordersData;

  if (loading === "loading") return <Preloader />;

  return (
    <>
      <PageHeader
        title={frontendPaths.order.title}
        onBack={() => navigate(-1)}
      />
      <Card>
        <ShowErrorMessages />
        {orderDetail && (
          <>
            <Descriptions title="Основная информация заказа">
              <Descriptions.Item label="Дата создания">
                {dayjs(orderDetail.createdAt).format(dateFormatFull)}
              </Descriptions.Item>
              <Descriptions.Item label="Код">
                {orderDetail.code}
              </Descriptions.Item>
              <Descriptions.Item label="Статус">
                <PaymentStatus status={orderDetail.status} />
              </Descriptions.Item>
              <Descriptions.Item label="Стоимость">
                {orderDetail.amount} ₽
              </Descriptions.Item>
              <Descriptions.Item label="Промокод">
                {orderDetail.promocode}
              </Descriptions.Item>
              <Descriptions.Item label="Скидка по промокоду">
                {orderDetail.promocodeDiscount}
              </Descriptions.Item>
            </Descriptions>
            <Divider />
            <Descriptions title="Владелец заказа">
              <Descriptions.Item label="Имя владельца">
                {getFullName(orderDetail.firstName, orderDetail.lastName)}
              </Descriptions.Item>
              <Descriptions.Item label="День рождения">
                {dayjs(orderDetail.birthday).format(dateFormatDate)}
              </Descriptions.Item>
              <Descriptions.Item label="Телефон">
                <a href={`tel:${orderDetail.phone}`}>{orderDetail.phone}</a>
              </Descriptions.Item>
            </Descriptions>
            <Divider />
            <Descriptions title="Даритель заказа">
              <Descriptions.Item label="Куплен в подарок">
                {orderDetail.gift ? "да" : "нет"}
              </Descriptions.Item>
              <Descriptions.Item label="Имя дарителя">
                {getFullName(
                  orderDetail.gifterFirstName,
                  orderDetail.gifterLastName
                )}
              </Descriptions.Item>
              <Descriptions.Item label="Телефон дарителя">
                <a href={`tel:${orderDetail.gifterPhone}`}>
                  {orderDetail.gifterPhone}
                </a>
              </Descriptions.Item>
            </Descriptions>
            <Divider />
            <Descriptions title="Предмет заказа">
              <Descriptions.Item label="Код акции">
                {orderDetail.productCode}
              </Descriptions.Item>
              <Descriptions.Item label="Акция">
                {orderDetail.productName}
              </Descriptions.Item>
              <Descriptions.Item label="Цена акции">
                {orderDetail.productPrice}
              </Descriptions.Item>
              <Descriptions.Item label="Клуб акции" span={3}>
                {orderDetail.clubList.items.map((club) => (
                  <Tag>{club.name}</Tag>
                ))}
              </Descriptions.Item>
            </Descriptions>
          </>
        )}
      </Card>
    </>
  );
}

export default OrderPage;
