import { useEffect } from "react";

import { useLogout } from "@/entities/auth";

function Logout() {
  const logout = useLogout();

  useEffect(() => {
    logout();
  }, []);

  return null;
}

export default Logout;
