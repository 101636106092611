import { Button, Card, PageHeader, Space, Select } from "antd";
import dayjs from "dayjs";
import { useEffect, useMemo, useState } from "react";
import { Link, useNavigate, useSearchParams } from "react-router-dom";

import DatePicker from "@/components/DatePicker";
import DeleteConfirmModal from "@/components/modals/DeleteConfirmModal";
import { ShowErrorMessages } from "@/entities/error";
import dayOfWeek from "@/constants/daysOfWeek";
import { useAppDispatch, useAppSelector } from "@/app";
import frontendPaths from "@/routes/paths";
import getFullName from "@/shared/utils/getFullName";
import mapFilter from "@/shared/utils/mapFilter";
import {
  copyEvents,
  deleteEvent,
  fetchEvents,
  getScheduleFilters,
  getScheduleLoading,
  IScheduleListRequestParams,
  scheduleActions,
  scheduleSelectors,
} from "@/entities/schedule";
import { coachsSelectors, fetchCoachs } from "@/entities/coachs";
import { clubsSelectors, fetchAllAreas } from "@/entities/club";
import { fetchProgramms, programmsSelectors } from "@/entities/programms";
import { useTableRowSelection } from "@/shared/hooks/useTableRowSelection";
import useFilters from "@/shared/hooks/useFilters";

import "./schedule.css";

import { WeekView } from "./WeekView";
import { TableView } from "./TableView";

const tabList = [
  {
    key: "WeekView",
    tab: "Календарь",
  },
  {
    key: "TableView",
    tab: "Таблица",
  },
];

function Schedule() {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const [searchParams] = useSearchParams();
  const { filters, onFiltersChange, resetFilters } = useFilters();
  const { selectedRowKeys, hasSelected, rowSelection } = useTableRowSelection();

  const [activeTabKey, setActiveTabKey] = useState<string>("WeekView");
  const onTabChange = (key: string) => setActiveTabKey(key);

  const [entityToDelete, setEntityToDelete] = useState<string | null>(null);

  useEffect(() => {
    void dispatch(scheduleActions.resetEventDetail());
    void dispatch(fetchAllAreas());
    void dispatch(fetchCoachs());
    void dispatch(fetchProgramms());
  }, []);

  useEffect(() => {
    const fetchParams: IScheduleListRequestParams = {
      yearAndWeekNumber: searchParams.get("yearAndWeek") || undefined,
      programCode: searchParams.getAll("programm"),
      teacherCode: searchParams.getAll("teacher"),
      areaCode: searchParams.getAll("area"),
      clubCode: searchParams.getAll("club"),
      weekday: searchParams.getAll("weekday"),
    };

    void dispatch(fetchEvents(fetchParams));
  }, [searchParams]);

  const loading = useAppSelector(getScheduleLoading);
  const storeFilters = useAppSelector(getScheduleFilters);
  const events = useAppSelector(scheduleSelectors.selectAll).map((el) => ({
    ...el,
    key: el.code as React.Key,
  }));
  const eventToDelete = useMemo(
    () => events.find((el) => el.code === entityToDelete),
    [events, entityToDelete]
  );

  const handleDeleteEntitySubmit = (code: string | null) => {
    if (code) {
      void dispatch(deleteEvent(code));
      setEntityToDelete(null);
    }
  };

  const handleDeleteEntityCancel = () => {
    setEntityToDelete(null);
  };

  const handleCopyEvents = () => {
    void dispatch(copyEvents(selectedRowKeys as string[]));
  };

  const programmsFilter = mapFilter(
    useAppSelector(programmsSelectors.selectAll)
  ).map((el) => ({ label: el.text, value: el.value }));
  const clubsFilter = useAppSelector(clubsSelectors.selectAll).map((el) => ({
    label: el.name,
    value: el.code,
  }));
  const coachsFilter =
    useAppSelector(coachsSelectors.selectAll)
      .map((item) => ({
        key: item.code,
        label: getFullName(item.firstName, item.lastName),
        value: item.code,
      }))
      .sort((a, b) => a.label.localeCompare(b.label)) || [];

  const tabContentList: Record<string, React.ReactNode> = {
    TableView: (
      <TableView
        events={events}
        loading={loading}
        onDelete={setEntityToDelete}
        rowSelection={rowSelection}
      />
    ),
    WeekView: <WeekView events={events} onDelete={setEntityToDelete} />,
  };

  return (
    <>
      <PageHeader
        title="Расписание"
        onBack={() => navigate(-1)}
        extra={
          <>
            <Button
              type="primary"
              disabled={!hasSelected}
              onClick={handleCopyEvents}
            >
              Дублировать на неделю
            </Button>
            <Link to={frontendPaths.schedule_create.URL()}>
              <Button type="primary">
                {frontendPaths.schedule_create.title}
              </Button>
            </Link>
          </>
        }
      />
      <Card
        tabList={tabList}
        activeTabKey={activeTabKey}
        onTabChange={onTabChange}
      >
        <ShowErrorMessages />
        <Space align="center" wrap>
          <DatePicker
            onChange={(_, stringValue) =>
              onFiltersChange({
                ...filters,
                yearAndWeek: [stringValue],
              })
            }
            value={
              storeFilters?.startDate
                ? dayjs(storeFilters?.startDate)
                : undefined
            }
            picker="week"
          />
          <Select
            options={dayOfWeek.map((el) => ({
              ...el,
              value: el.value.toString(),
            }))}
            style={{ width: 120 }}
            allowClear
            placeholder="День недели"
            onChange={(value) =>
              onFiltersChange({
                ...filters,
                weekday: value,
              })
            }
            value={storeFilters?.weekday || undefined}
          />
          <Select
            options={programmsFilter}
            style={{ width: 160 }}
            allowClear
            placeholder="Программа"
            mode="multiple"
            maxTagCount="responsive"
            onChange={(value) =>
              onFiltersChange({
                ...filters,
                programm: [...value],
              })
            }
            value={storeFilters?.programCode || undefined}
          />
          <Select
            options={clubsFilter}
            style={{ width: 160 }}
            allowClear
            placeholder="Клуб"
            maxTagCount="responsive"
            onChange={(value) =>
              onFiltersChange({
                ...filters,
                club: value,
              })
            }
            value={storeFilters?.clubCode || undefined}
          />
          <Select
            options={coachsFilter}
            style={{ width: 160 }}
            allowClear
            placeholder="Тренер"
            maxTagCount="responsive"
            onChange={(value) =>
              onFiltersChange({
                ...filters,
                teacher: value,
              })
            }
            value={storeFilters?.teacherCode || undefined}
          />
          <Button onClick={resetFilters}>Сбросить</Button>
        </Space>
        <br />
        <br />

        {tabContentList[activeTabKey]}
      </Card>
      <DeleteConfirmModal
        isOpen={Boolean(entityToDelete)}
        onOk={() => handleDeleteEntitySubmit(entityToDelete)}
        onCancel={handleDeleteEntityCancel}
        text={`${eventToDelete?.program.name} в ${dayjs(
          eventToDelete?.startedAt
        ).format("HH:mm")}`}
      />
    </>
  );
}

export default Schedule;
