import { Modal } from "antd";

import { ShowErrorMessages } from "@/entities/error";

interface IProps {
  isOpen: boolean;
  onOk: () => void;
  onCancel: () => void;
  text?: string;
}

function DeleteConfirmModal(props: IProps) {
  const { isOpen, onOk, onCancel, text } = props;

  return (
    <Modal title="Удаление" open={isOpen} onOk={onOk} onCancel={onCancel}>
      <ShowErrorMessages />
      <p>Удалить: {text}?</p>
    </Modal>
  );
}

export default DeleteConfirmModal;
