import { Button, Card, Form, PageHeader, Upload } from "antd";
import { useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";

import Preloader from "@/components/Preloader";
import { ShowErrorMessages, errorActions } from "@/entities/error";
import * as form from "@/constants/formsWrappers";
import { useAppDispatch, useAppSelector } from "@/app";
import {
  convertIFileResponseToPhotoListItems,
  createInitUploadConfig,
} from "@/shared/models/files";
import frontendPaths from "@/routes/paths";
import customUpload, { normFile } from "@/shared/utils/customUpload";
import {
  editClubPhotos,
  fetchClub,
  fetchClubPhotos,
  getClubDetail,
  getClubLoading,
  getClubPhotos,
} from "@/entities/club";
import {
  IClubEditPhotosValues,
  IClubsEditPhotosArgs,
} from "@/entities/club/model/types/clubs";

import PageNotFound from "./PageNotFound";

function ClubPhotoEdit() {
  const param = useParams();
  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  useEffect(() => {
    void dispatch(errorActions.resetErrors());
    if (param.id) {
      void dispatch(fetchClub(param.id));
      void dispatch(fetchClubPhotos(param.id));
    }
  }, []);

  const clubDetail = useAppSelector(getClubDetail);
  const loading = useAppSelector(getClubLoading);
  const clubPhotos = useAppSelector(getClubPhotos);

  const initFile = createInitUploadConfig(clubPhotos);

  const handleUpdateClubAddress = (values: IClubEditPhotosValues) => {
    if (!param.id || !values.photo || !Array.isArray(values.photo)) return;

    const args: IClubsEditPhotosArgs = {
      code: param.id,
      photos: convertIFileResponseToPhotoListItems(values.photo),
    };
    void dispatch(editClubPhotos(args));
  };

  if (!param.id || loading === "failed") {
    return <PageNotFound />;
  }

  if (loading === "loading") {
    return <Preloader />;
  }

  return clubDetail ? (
    <>
      <PageHeader
        title={`${frontendPaths.club_photos_edit.title}: ${clubDetail.clubName}`}
        onBack={() => navigate(-1)}
      />
      <Card>
        <ShowErrorMessages />
        <Form
          name="editClubPhotos"
          labelCol={form.LebelCol}
          wrapperCol={form.WrapperCol}
          autoComplete="off"
          initialValues={{
            photo: initFile,
          }}
          onFinish={handleUpdateClubAddress}
        >
          <Form.Item
            label="Фотографии клуба"
            name="photo"
            valuePropName="fileList"
            getValueFromEvent={normFile}
            rules={[
              {
                required: true,
                message: "Пожалуйста укажите фото клуба",
              },
            ]}
          >
            <Upload
              accept="image/*"
              customRequest={customUpload}
              listType="picture-card"
              multiple
              maxCount={10}
              defaultFileList={initFile}
            >
              <Button type="link">Загрузить</Button>
            </Upload>
          </Form.Item>

          <Form.Item wrapperCol={form.SubmitCol}>
            <Button type="primary" htmlType="submit">
              Сохранить
            </Button>
          </Form.Item>
        </Form>
      </Card>
    </>
  ) : null;
}

export default ClubPhotoEdit;
