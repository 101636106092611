import {
  Button,
  Card,
  Checkbox,
  DatePicker,
  Form,
  Input,
  PageHeader,
  Select,
} from "antd";
import dayjs from "dayjs";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

import { ShowErrorMessages, errorActions } from "@/entities/error";
import * as form from "@/constants/formsWrappers";
import { useAppDispatch, useAppSelector } from "@/app";
import frontendPaths from "@/routes/paths";
import getFullName from "@/shared/utils/getFullName";
import { IScheduleCreateValues, createEvent } from "@/entities/schedule";
import { IScheduleCreateArgs } from "@/entities/schedule/model/types/schedule";
import { coachsSelectors, fetchCoachs } from "@/entities/coachs";
import { fetchAllAreas } from "@/entities/club";
import { fetchProgramms, programmsSelectors } from "@/entities/programms";

const { TextArea } = Input;

function ScheduleCreate() {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const [isCreated, setIsCreated] = useState(false);

  useEffect(() => {
    void dispatch(errorActions.resetErrors());
    void dispatch(fetchProgramms());
    void dispatch(fetchCoachs());
    void dispatch(fetchAllAreas());
  }, []);

  const programms = useAppSelector(programmsSelectors.selectAll);
  const programmsOptions = programms.map((item) => ({
    value: item.code,
    label: item.name,
  }));

  const coachs = useAppSelector(coachsSelectors.selectAll);
  const coachsOptions = coachs.map((item) => ({
    value: item.code,
    label: getFullName(item.firstName, item.lastName),
  }));

  const clubsData = useAppSelector(({ clubs }) => clubs);
  const areas = clubsData.clubAllAreas;
  const areasOptions = areas?.map((item) => ({
    value: item.code,
    label: `${item.name} (${item.club.name})`,
  }));

  const handleScheduleCreate = (values: IScheduleCreateValues) => {
    const teacher = coachs.find((item) => item.code === values.teacherCode);
    const area = areas?.find((item) => item.code === values.areaCode);
    const program = programms.find((item) => item.code === values.programCode);
    if (teacher && area && program) {
      const request: IScheduleCreateArgs = {
        startedAt: dayjs(values.startedAt).format("YYYY-MM-DD HH:mm:ss"),
        paid: values.paid,
        comment: values.comment,
        teacher,
        area,
        program,
      };
      void dispatch(createEvent(request)).then((res) => {
        if (res.meta.requestStatus === "fulfilled") setIsCreated(true);
      });
    }
  };

  return (
    <>
      <PageHeader
        title={frontendPaths.schedule_create.title}
        onBack={() => navigate(-1)}
      />
      <Card>
        <ShowErrorMessages />
        <Form
          name="createEvent"
          labelCol={form.LebelColWide}
          wrapperCol={form.WrapperColWide}
          autoComplete="off"
          onFinish={handleScheduleCreate}
          disabled={isCreated}
        >
          <Form.Item
            label="Дата и время начала"
            name="startedAt"
            rules={[
              {
                required: true,
                message: "Пожалуйста укажите дату и время начала",
              },
            ]}
          >
            <DatePicker
              showTime={{
                format: "HH:mm",
              }}
              format="YYYY-MM-DD HH:mm"
              minuteStep={5}
            />
          </Form.Item>

          <Form.Item
            label="Программа"
            name="programCode"
            rules={[
              {
                required: true,
                message: "Пожалуйста укажите программу",
              },
            ]}
          >
            <Select options={programmsOptions} />
          </Form.Item>

          <Form.Item
            label="Тренер"
            name="teacherCode"
            rules={[
              {
                required: true,
                message: "Пожалуйста укажите количество калорий",
              },
            ]}
          >
            <Select options={coachsOptions} />
          </Form.Item>

          <Form.Item
            label="Зал и клуб"
            name="areaCode"
            rules={[
              {
                required: true,
                message: "Пожалуйста укажите зал и клуб",
              },
            ]}
          >
            <Select options={areasOptions} />
          </Form.Item>

          <Form.Item
            label="Платное участие"
            name="paid"
            valuePropName="checked"
          >
            <Checkbox>Да</Checkbox>
          </Form.Item>

          <Form.Item label="Комментарии" name="comment">
            <TextArea rows={6} />
          </Form.Item>

          <Form.Item wrapperCol={form.SubmitCol}>
            <Button type="primary" htmlType="submit">
              Сохранить
            </Button>
          </Form.Item>
        </Form>
      </Card>
    </>
  );
}

export default ScheduleCreate;
