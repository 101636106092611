import { Button, Card, Checkbox, Form, Input, PageHeader, Select } from "antd";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

import { ShowErrorMessages, errorActions } from "@/entities/error";
import TextEditor from "@/components/TextEditor";
import * as form from "@/constants/formsWrappers";
import { slugRegExp } from "@/constants/params";
import { useAppDispatch } from "@/app";
import frontendPaths from "@/routes/paths";
import { IDocEditValues, createDoc } from "@/entities/docs";

export const docsColorOptions = [
  {
    value: "red",
    label: "Красный",
  },
  {
    value: "white",
    label: "Белый",
  },
];

function DocCreate() {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const [isCreated, setIsCreated] = useState(false);

  useEffect(() => {
    void dispatch(errorActions.resetErrors());
  }, []);

  const handleDocCreate = async (values: IDocEditValues) => {
    await dispatch(createDoc(values)).then((res) => {
      if (res.meta.requestStatus === "fulfilled") setIsCreated(true);
    });
  };

  return (
    <>
      <PageHeader
        title={frontendPaths.doc_create.title}
        onBack={() => navigate(-1)}
      />
      <Card>
        <ShowErrorMessages />
        <Form
          name="createProgramm"
          labelCol={form.LebelColWide}
          wrapperCol={form.WrapperColWide}
          autoComplete="off"
          onFinish={handleDocCreate}
          disabled={isCreated}
        >
          <Form.Item
            label="Заголовок"
            name="name"
            rules={[
              {
                required: true,
                message: "Пожалуйста укажите заголовок документа",
              },
            ]}
          >
            <Input />
          </Form.Item>

          <Form.Item
            label="slug"
            name="slug"
            rules={[
              {
                required: true,
                message: "Пожалуйста укажите slug",
              },
              {
                required: true,
                pattern: new RegExp(slugRegExp),
                message: "Только латинские буквы и цифры",
              },
            ]}
          >
            <Input />
          </Form.Item>

          <Form.Item
            label="Цвет"
            name="color"
            rules={[
              {
                required: true,
                message: "Пожалуйста укажите цвет ярлыка документа",
              },
            ]}
          >
            <Select options={docsColorOptions} />
          </Form.Item>

          <Form.Item
            label="Показывать на главной?"
            name="mainPageShow"
            valuePropName="checked"
          >
            <Checkbox>Да</Checkbox>
          </Form.Item>

          <Form.Item
            label="Описание"
            name="description"
            rules={[
              {
                required: true,
                message: "Пожалуйста укажите описание программы",
              },
            ]}
          >
            <TextEditor value="description" readonly={isCreated} />
          </Form.Item>

          <Form.Item wrapperCol={form.SubmitCol}>
            <Button type="primary" htmlType="submit">
              Сохранить
            </Button>
          </Form.Item>
        </Form>
      </Card>
    </>
  );
}

export default DocCreate;
