import { Button, Card, PageHeader, Pagination, Table } from "antd";
import { ColumnProps } from "antd/lib/table";
import { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";

import { ImagePreview } from "@/components/ImagePreview";
import DeleteConfirmModal from "@/components/modals/DeleteConfirmModal";
import { ShowErrorMessages, errorActions } from "@/entities/error";
import TableActionsMenu from "@/components/TableActionsMenu";
import TooltipDate from "@/components/TooltipDate";
import { useAppDispatch, useAppSelector } from "@/app";
import frontendPaths from "@/routes/paths";
import addReactKey from "@/shared/utils/addReactKey";
import {
  deleteOffer,
  fetchOffers,
  IOfferListItem,
  offersSelectors,
} from "@/entities/offers";

function Offers() {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  const [isOfferDeleteMode, setOfferDeleteMode] = useState(false);
  const [removableOffer, setRemovableOffer] = useState<IOfferListItem | null>(
    null
  );

  useEffect(() => {
    void dispatch(errorActions.resetErrors());
    void dispatch(fetchOffers(1));
  }, []);

  const offersData = useAppSelector(({ offers }) => offers);
  const { loading, pagination } = offersData;
  const offers = addReactKey<IOfferListItem>(
    useAppSelector(offersSelectors.selectAll)
  );

  const onPageChange = (page: number) => {
    void dispatch(fetchOffers(page));
  };

  // delete promocode
  const handleTurnOnOfferDeleteMode = (code: string) => {
    const promocodeToRemove = offers.find((item) => item.code === code);
    if (promocodeToRemove) {
      setOfferDeleteMode(true);
      setRemovableOffer(promocodeToRemove);
    }
  };

  const handleDeleteProductSubmit = (code: string) => {
    void dispatch(deleteOffer(code));
    setOfferDeleteMode(false);
    setRemovableOffer(null);
  };

  const handleDeleteProductCancel = () => {
    setOfferDeleteMode(false);
    setRemovableOffer(null);
  };

  const columns: ColumnProps<IOfferListItem>[] = [
    {
      title: "Метка",
      dataIndex: "name",
      key: "name",
    },
    {
      title: "Название",
      dataIndex: "title",
      key: "title",
    },
    {
      title: "Фото",
      dataIndex: "photo",
      key: "photo",
      responsive: ["xxl", "xl", "lg", "md"],
      render: (_, record) =>
        record?.photo ? (
          <ImagePreview
            photoCode={record.photo.code}
            imageSrc={record.photo.urlPath}
          />
        ) : null,
    },
    {
      title: "Действует до",
      dataIndex: "endAt",
      key: "endAt",
      width: 130,
      responsive: ["xxl", "xl", "lg", "md"],
      render: (_, record) => <TooltipDate date={record.endAt} />,
    },
    {
      title: "Действие",
      dataIndex: "action",
      key: "action",
      align: "right",
      render: (_, record) => (
        <TableActionsMenu
          actions={[
            {
              title: "Редактировать",
              url: frontendPaths.offer_edit.URL(record.code),
            },
            {
              title: "Удалить",
              url: null,
              action: () => handleTurnOnOfferDeleteMode(record.code),
            },
          ]}
        />
      ),
    },
  ];

  return (
    <>
      <PageHeader
        title="Спец-предложения"
        onBack={() => navigate(-1)}
        extra={
          <Link to={frontendPaths.offer_create.URL()}>
            <Button type="primary">{frontendPaths.offer_create.title}</Button>
          </Link>
        }
      />
      <Card>
        <ShowErrorMessages />
        <Table
          dataSource={offers}
          columns={columns}
          loading={loading === "loading"}
          pagination={false}
        />
        {pagination && (
          <Pagination
            current={pagination.currentPage}
            pageSize={pagination.pageSize}
            total={pagination.total}
            showSizeChanger={false}
            onChange={onPageChange}
          />
        )}
      </Card>
      {removableOffer && (
        <DeleteConfirmModal
          isOpen={isOfferDeleteMode}
          onOk={() => handleDeleteProductSubmit(removableOffer.code)}
          onCancel={handleDeleteProductCancel}
          text={`Удалить спец-предложение: ${removableOffer.title}?`}
        />
      )}
    </>
  );
}

export default Offers;
