import { FilterValue, TablePaginationConfig } from "antd/lib/table/interface";
import { useState } from "react";
import { useSearchParams, useNavigate } from "react-router-dom";

import isStringArray from "@/shared/utils/typeGueards";

// import isStringArray from "@/shared/utils/typeGuards";

const useTableFilterChange = () => {
  const [searchParams] = useSearchParams();
  const navigate = useNavigate();
  const [tableFilters, setTableFilters] =
    useState<Record<string, FilterValue | null>>();

  const onTableChange = (
    _: TablePaginationConfig,
    filtersArg: Record<string, FilterValue | null>
  ) => {
    setTableFilters(filtersArg);

    const keys = Array.from(searchParams.keys());
    // eslint-disable-next-line no-restricted-syntax
    for (const key of keys) searchParams.delete(key);

    Object.entries(filtersArg).forEach(([key, values]) => {
      if (values && isStringArray(values)) {
        values.forEach((value) => {
          value && searchParams.append(key, value);
        });
      }
    });

    searchParams.set("page", "1");
    navigate(`?${searchParams.toString()}`);
  };

  const onPageChange = (page: number) => {
    searchParams.set("page", page.toString());
    navigate(`?${searchParams.toString()}`);
  };

  return { onTableChange, onPageChange, tableFilters };
};

export default useTableFilterChange;
