import { ArrowDownOutlined, ArrowUpOutlined } from "@ant-design/icons";
import {
  Button,
  Card,
  PageHeader,
  Pagination,
  Switch,
  Table,
  Tag,
  Space,
} from "antd";
import { ColumnProps } from "antd/lib/table";
import { ColumnFilterItem } from "antd/lib/table/interface";
import { useEffect, useState } from "react";
import { Link, useNavigate, useSearchParams } from "react-router-dom";

import DeleteConfirmModal from "@/components/modals/DeleteConfirmModal";
import { ShowErrorMessages, errorActions } from "@/entities/error";
import TableActionsMenu from "@/components/TableActionsMenu";
import { useAppDispatch, useAppSelector } from "@/app";
import useTableFilterChange from "@/shared/hooks/useTableFilterChange";
import frontendPaths from "@/routes/paths";
import addReactKey from "@/shared/utils/addReactKey";
import isStringArray from "@/shared/utils/typeGueards";
import { clubsSelectors } from "@/entities/club";
import {
  deleteProduct,
  fetchProducts,
  fetchProductsParams,
  IProductListItem,
  productsSelectors,
  sortProduct,
} from "@/entities/products";

function Products() {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  const [searchParams] = useSearchParams();
  const { onTableChange, onPageChange } = useTableFilterChange();
  const [isProductDeleteMode, setProductDeleteMode] = useState(false);
  const [removableProduct, setRemovableProduct] =
    useState<IProductListItem | null>(null);

  const productsData = useAppSelector(({ products }) => products);
  const { loading, pagination } = productsData;
  const products = addReactKey<IProductListItem>(
    useAppSelector(productsSelectors.selectAll)
  );

  const filters: Record<string, string | string[]> = {};

  const getProducts = () => {
    const fetchParams: fetchProductsParams = {
      page: Number(searchParams.get("page")) || 1,
    };

    filters.clubs = searchParams.getAll("club");

    if (filters.clubs && isStringArray(filters.clubs)) {
      fetchParams.clubCodes = filters.clubs;
    }

    void dispatch(fetchProducts(fetchParams));
  };

  useEffect(() => {
    void dispatch(errorActions.resetErrors());
    getProducts();
  }, [searchParams]);

  // delete product
  const handleTurnOnProductDeleteMode = (code: string) => {
    const newsToRemove = products.find((item) => item.code === code);
    if (newsToRemove) {
      setProductDeleteMode(true);
      setRemovableProduct(newsToRemove);
    }
  };

  const handleDeleteProductSubmit = (code: string) => {
    void dispatch(deleteProduct(code));
    setProductDeleteMode(false);
    setRemovableProduct(null);
  };

  const handleDeleteProductCancel = () => {
    setProductDeleteMode(false);
    setRemovableProduct(null);
  };

  const clubsFilter: ColumnFilterItem[] = useAppSelector(
    clubsSelectors.selectAll
  ).map((item) => ({
    key: item.code,
    text: item.name,
    value: item.code,
  }));

  const onSort = (code: string, direction: "up" | "down") => {
    void dispatch(sortProduct({ code, direction }))
      .unwrap()
      .then(() => getProducts());
  };

  const columns: ColumnProps<IProductListItem>[] = [
    {
      title: "Название",
      dataIndex: "title",
      key: "title",
    },
    {
      title: "Цена",
      dataIndex: "price",
      key: "price",
    },
    {
      title: "Клуб",
      dataIndex: "club",
      key: "clubs",
      responsive: ["xxl", "xl", "lg", "md"],
      filters: clubsFilter,
      defaultFilteredValue: productsData.filters.clubs,
      render: (_, record) =>
        record.clubs.map((club) => <Tag key={club.code}>{club.name}</Tag>),
    },
    {
      title: "Активна",
      dataIndex: "active",
      responsive: ["xxl", "xl", "lg", "md"],
      key: "active",
      render: (_, record) => (
        <Switch size="small" checked={record.active} disabled />
      ),
    },
    {
      title: "Переместить",
      dataIndex: "moove",
      responsive: ["xxl", "xl", "lg", "md"],
      key: "moove",
      render: (_, record) => (
        <Space>
          <Button
            icon={<ArrowUpOutlined />}
            size="small"
            onClick={() => onSort(record.code, "up")}
          />
          <Button
            icon={<ArrowDownOutlined />}
            size="small"
            onClick={() => onSort(record.code, "down")}
          />
        </Space>
      ),
    },
    {
      title: "Действие",
      dataIndex: "action",
      key: "action",
      align: "right",
      render: (_, record) => (
        <TableActionsMenu
          actions={[
            {
              title: "Редактировать",
              url: frontendPaths.product_edit.URL(record.code),
            },
            {
              title: "Удалить",
              url: null,
              action: () => handleTurnOnProductDeleteMode(record.code),
            },
          ]}
        />
      ),
    },
  ];

  return (
    <>
      <PageHeader
        title={frontendPaths.products.title}
        onBack={() => navigate(-1)}
        extra={
          <Link to={frontendPaths.product_create.URL()}>
            <Button type="primary">{frontendPaths.product_create.title}</Button>
          </Link>
        }
      />
      <Card>
        <ShowErrorMessages />
        <Table
          dataSource={products}
          columns={columns}
          pagination={false}
          onChange={onTableChange}
          loading={loading === "loading"}
        />
        {pagination && (
          <Pagination
            current={pagination.currentPage}
            pageSize={pagination.pageSize}
            total={pagination.total}
            showSizeChanger={false}
            onChange={onPageChange}
          />
        )}
      </Card>
      {removableProduct && (
        <DeleteConfirmModal
          isOpen={isProductDeleteMode}
          onOk={() => handleDeleteProductSubmit(removableProduct.code)}
          onCancel={handleDeleteProductCancel}
          text={`Удалить акцию: ${removableProduct.title}?`}
        />
      )}
    </>
  );
}

export default Products;
