import {
  Button,
  Card,
  Checkbox,
  Form,
  Input,
  InputNumber,
  PageHeader,
  Select,
  Switch,
} from "antd";
import { useNavigate } from "react-router-dom";
import { useEffect, useState } from "react";

import { ShowErrorMessages, errorActions } from "@/entities/error";
import * as form from "@/constants/formsWrappers";
import { useAppDispatch, useAppSelector } from "@/app";
import frontendPaths from "@/routes/paths";
import { clubsSelectors } from "@/entities/club";
import { IProductEditValues, createProduct } from "@/entities/products";

const { TextArea } = Input;

function ProductCreate() {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const [isCreated, setIsCreated] = useState(false);

  useEffect(() => {
    void dispatch(errorActions.resetErrors());
  }, []);

  const clubs = useAppSelector(clubsSelectors.selectAll);
  const clubsOptions = clubs.map((item) => ({
    value: item.code,
    label: item.name,
  }));

  const handleProductCreate = (values: IProductEditValues) => {
    void dispatch(createProduct(values)).then((res) => {
      if (res.meta.requestStatus === "fulfilled") setIsCreated(true);
    });
  };

  return (
    <>
      <PageHeader
        title={frontendPaths.product_create.title}
        onBack={() => navigate(-1)}
      />
      <Card>
        <ShowErrorMessages />
        <Form
          name="createProgramm"
          labelCol={form.LebelColWide}
          wrapperCol={form.WrapperColWide}
          autoComplete="off"
          onFinish={handleProductCreate}
          disabled={isCreated}
        >
          <Form.Item
            label="Активна"
            name="active"
            valuePropName="checked"
            initialValue
          >
            <Switch />
          </Form.Item>

          <Form.Item
            label="Заголовок акции"
            name="title"
            rules={[
              {
                required: true,
                message: "Пожалуйста укажите заголовок акции",
              },
            ]}
          >
            <Input />
          </Form.Item>

          <Form.Item label="Старая цена" name="oldPrice">
            <InputNumber precision={2} stringMode />
          </Form.Item>

          <Form.Item
            label="Цена"
            name="price"
            rules={[
              {
                required: true,
                message: "Пожалуйста укажите цену",
              },
            ]}
          >
            <InputNumber precision={2} stringMode />
          </Form.Item>

          <Form.Item
            label="Разрешить промокоды"
            name="promocode"
            valuePropName="checked"
          >
            <Checkbox>Да</Checkbox>
          </Form.Item>

          <Form.Item
            label="Клубы"
            name="clubCodes"
            rules={[
              {
                required: true,
                message: "Пожалуйста выберите клубы",
              },
            ]}
          >
            <Select options={clubsOptions} mode="multiple" />
          </Form.Item>

          <Form.Item
            label="Описание акции"
            name="description"
            tooltip="Переводы строк преобразуются в галочки"
            rules={[
              {
                required: true,
                message: "Пожалуйста укажите описание акции",
              },
            ]}
          >
            <TextArea rows={4} showCount maxLength={150} />
          </Form.Item>

          <Form.Item wrapperCol={form.SubmitCol}>
            <Button type="primary" htmlType="submit">
              Сохранить
            </Button>
          </Form.Item>
        </Form>
      </Card>
    </>
  );
}

export default ProductCreate;
