import { createAsyncThunk } from "@reduxjs/toolkit";
import { AxiosError } from "axios";

import { axiosWithCredentials } from "@/api/axios";
import backendPaths from "@/constants/backendPaths";
import { IThunkConfig } from "@/app";

import { IRefreshResponse } from "../types/auth";

export const refreshToken = createAsyncThunk<
  IRefreshResponse,
  undefined,
  IThunkConfig
>("auth/refreshToken", async (_, thunkAPI) => {
  const { rejectWithValue } = thunkAPI;
  try {
    const response = await axiosWithCredentials.post(
      backendPaths.REFRESH_TOKEN_URL()
    );
    return response.data as IRefreshResponse;
  } catch (err) {
    if (err instanceof AxiosError) return rejectWithValue(err.response?.data);
    throw err;
  }
});
