import { createAsyncThunk } from "@reduxjs/toolkit";
import axios, { AxiosError } from "axios";

import backendPaths from "@/constants/backendPaths";
import { IThunkConfig } from "@/app";
import { errorActions } from "@/entities/error";

import {
  INewsCreateArgs,
  INewsCreateRequest,
  INewsCreateResponse,
} from "../types/news";

export const createNews = createAsyncThunk<
  INewsCreateResponse,
  INewsCreateArgs,
  IThunkConfig
>("news/createNews", async (args: INewsCreateArgs, thunkAPI) => {
  const { rejectWithValue, dispatch } = thunkAPI;
  try {
    const request: INewsCreateRequest = {
      title: args.title,
      date: args.date,
      content: args.content,
      photoCode: args.photo.code,
    };
    const response = await axios.post(
      backendPaths.NEWS_CREATE_URL(),
      JSON.stringify(request)
    );
    dispatch(
      errorActions.addSuccessMessage({
        message: "Новость добавлена",
        type: "success",
      })
    );
    return response.data as INewsCreateResponse;
  } catch (err) {
    if (err instanceof AxiosError) return rejectWithValue(err.response?.data);
    throw err;
  }
});
