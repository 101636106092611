import { DollarCircleOutlined, ClockCircleOutlined } from "@ant-design/icons";
import dayjs, { Dayjs } from "dayjs";

import { IScheduleListItem } from "@/entities/schedule";
import frontendPaths from "@/routes/paths";
import TableActionsMenu from "@/components/TableActionsMenu";

type IProps = {
  events: IScheduleListItem[];
  onDelete: (entity: string | null) => void;
};

export function WeekView(props: IProps) {
  const { events, onDelete } = props;

  const geWeekRangeByDate = (firstDate: string): Dayjs[] => {
    if (!firstDate) return [];
    const firstDayOfWeek = dayjs(firstDate).startOf("week");
    const range = new Array(7).fill("");
    return range.reduce((acc, _item, index) => {
      return [...acc, firstDayOfWeek.add(index, "day").locale("ru")];
    }, []);
  };

  const weekRange = geWeekRangeByDate(events[0]?.startedAt);

  const eventsByTime = events.reduce<
    Record<string, Record<string, IScheduleListItem[]>>
  >((acc, item) => {
    const time = dayjs(item.startedAt).format("HH:mm");
    const day = dayjs(item.startedAt).format("DD.MM");
    const newAcc = { ...acc };

    if (time in newAcc) {
      if (day in newAcc[time]) {
        newAcc[time][day].push(item);
      } else {
        newAcc[time][day] = [item];
      }
    } else {
      newAcc[time] = { [day]: [item] };
    }

    return newAcc;
  }, {});

  return (
    <table className="schedule">
      <thead>
        <tr>
          <th>
            <ClockCircleOutlined />
          </th>
          {weekRange.map((day) => (
            <th key={dayjs(day).format("DD.MM")}>
              <div>{dayjs(day).format("DD.MM")}</div>
              <div>{dayjs(day).format("dddd")}</div>
            </th>
          ))}
        </tr>
      </thead>
      <tbody>
        {Object.keys(eventsByTime)
          .sort((a, b) => a.localeCompare(b))
          .map((time) => (
            <tr key={time} className={time}>
              <td className="app__time">{time}</td>
              {weekRange.map((day) => (
                <td key={dayjs(day).format("DD.MM")}>
                  {eventsByTime[time][dayjs(day).format("DD.MM")]?.map((el) => (
                    <div
                      className={`schedule__item ${
                        dayjs(el.startedAt).diff(dayjs(), "days") < 0
                          ? "row-past-event"
                          : ""
                      }`}
                      key={el.code}
                    >
                      <div className="item-title">
                        {el.paid ? (
                          <DollarCircleOutlined className="item-paid" />
                        ) : null}
                        {el.program.name}
                      </div>
                      <div className="item-teacher">{`${el.teacher.firstName} ${el.teacher.lastName}`}</div>
                      <div className="item-club">
                        {el.area.club.name} ({el.area.name})
                      </div>
                      <TableActionsMenu
                        actions={[
                          {
                            title: "Редактировать",
                            url: frontendPaths.schedule_edit.URL(el.code),
                          },
                          {
                            title: "Удалить",
                            url: null,
                            action: () => onDelete(el.code),
                          },
                        ]}
                        className="item-menu"
                      />
                    </div>
                  ))}
                </td>
              ))}
            </tr>
          ))}
      </tbody>
    </table>
  );
}
