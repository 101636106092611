import { Card, PageHeader, Pagination, Table, Tag } from "antd";
import { ColumnProps } from "antd/lib/table";
import { useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";

import PaymentStatus from "@/components/PaymentStatus";
import { ShowErrorMessages, errorActions } from "@/entities/error";
import TooltipDate from "@/components/TooltipDate";
import { useAppDispatch, useAppSelector } from "@/app";
import frontendPaths from "@/routes/paths";
import addReactKey from "@/shared/utils/addReactKey";
import getFullName from "@/shared/utils/getFullName";
import {
  IOrdersListItem,
  fetchOrders,
  ordersSelectors,
} from "@/entities/orders";

function Orders() {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  useEffect(() => {
    void dispatch(errorActions.resetErrors());
    void dispatch(fetchOrders({ page: 1 }));
  }, []);

  const ordersData = useAppSelector(({ orders }) => orders);
  const { loading, pagination } = ordersData;
  const orders = addReactKey<IOrdersListItem>(
    useAppSelector(ordersSelectors.selectAll)
  );

  const onPageChange = (page: number) => {
    void dispatch(fetchOrders({ page }));
  };

  const columns: ColumnProps<IOrdersListItem>[] = [
    {
      title: "Дата создания",
      dataIndex: "createdAt",
      key: "createdAt",
      render: (_, record) => (
        <Link to={frontendPaths.order.URL(record.code)}>
          <TooltipDate date={record.createdAt} />
        </Link>
      ),
    },
    {
      title: "Имя",
      dataIndex: "firstName",
      key: "firstName",
      render: (_, record) => getFullName(record.firstName, record.lastName),
    },
    {
      title: "Акция",
      dataIndex: "product",
      key: "product",
      responsive: ["xxl", "xl", "lg", "md"],
      render: (_, record) => record.productName,
    },
    {
      title: "Клуб",
      dataIndex: "clubs",
      key: "clubs",
      responsive: ["xxl", "xl", "lg", "md"],
      render: (_, record) =>
        record.clubList.items.map((club) => <Tag>{club.name}</Tag>),
    },
    {
      title: "Промокод",
      dataIndex: "promocode",
      key: "promocode",
      responsive: ["xxl", "xl", "lg", "md"],
      render: (_, record) => record.promocode,
    },
    {
      title: "Сумма",
      dataIndex: "amount",
      key: "amount",
    },
    {
      title: "Статус",
      dataIndex: "status",
      key: "status",
      render: (_, record) => <PaymentStatus status={record.status} />,
    },
  ];

  return (
    <>
      <PageHeader
        title={frontendPaths.orders.title}
        onBack={() => navigate(-1)}
      />
      <Card>
        <ShowErrorMessages />
        <Table
          dataSource={orders}
          columns={columns}
          loading={loading === "loading"}
          pagination={false}
        />
        {pagination && (
          <Pagination
            current={pagination.currentPage}
            pageSize={pagination.pageSize}
            total={pagination.total}
            showSizeChanger={false}
            onChange={onPageChange}
          />
        )}
      </Card>
    </>
  );
}

export default Orders;
