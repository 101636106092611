import { configureStore } from "@reduxjs/toolkit";
import { TypedUseSelectorHook, useDispatch, useSelector } from "react-redux";

import { scheduleReducer } from "@/entities/schedule";
import { coachReducer } from "@/entities/coachs";
import { docsReducer } from "@/entities/docs";
import { clubReducer } from "@/entities/club";
import { offerReducer } from "@/entities/offers";
import { newsReducer } from "@/entities/news";
import { formQuerieReducer } from "@/entities/formQueries";
import { orderReducer } from "@/entities/orders";
import { productReducer } from "@/entities/products";
import { sliderReducer } from "@/entities/sliders";
import { optionIconsReducer } from "@/entities/clubOptionsIcons";
import { programmReducer } from "@/entities/programms";
import { promocodesReducer } from "@/entities/promocodes";
import { streetTypesReducer } from "@/entities/streetTypesSlice";
import { authReducer } from "@/entities/auth";
import { errorReducer } from "@/entities/error";
import { IThunkCustomError } from "@/entities/error/model/types/error";

export const store = configureStore({
  reducer: {
    auth: authReducer,
    error: errorReducer,
    clubs: clubReducer,
    optionIcons: optionIconsReducer,
    streetTypes: streetTypesReducer,
    coachs: coachReducer,
    programms: programmReducer,
    schedule: scheduleReducer,
    docs: docsReducer,
    news: newsReducer,
    products: productReducer,
    promocodes: promocodesReducer,
    offers: offerReducer,
    orders: orderReducer,
    formQueries: formQuerieReducer,
    sliders: sliderReducer,
  },
});

export type StoreType = typeof store;
export type RootState = ReturnType<typeof store.getState>;
type AppDispatch = typeof store.dispatch;
export const useAppDispatch: () => AppDispatch = useDispatch;
export const useAppSelector: TypedUseSelectorHook<RootState> = useSelector;

export interface IThunkConfig {
  rejectValue: IThunkCustomError;
  state: RootState;
}
