import {
  Button,
  Card,
  Checkbox,
  Form,
  Input,
  InputNumber,
  PageHeader,
  Select,
  Switch,
} from "antd";
import { useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";

import Preloader from "@/components/Preloader";
import { ShowErrorMessages, errorActions } from "@/entities/error";
import * as form from "@/constants/formsWrappers";
import { useAppDispatch, useAppSelector } from "@/app";
import { clubsSelectors } from "@/entities/club";
import {
  IProductEditRequestArgs,
  IProductEditValues,
  editProduct,
  fetchProduct,
} from "@/entities/products";

import PageNotFound from "./PageNotFound";

const { TextArea } = Input;

function PromocodeEdit() {
  const param = useParams();
  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  useEffect(() => {
    void dispatch(errorActions.resetErrors());
    param.id && dispatch(fetchProduct(param.id));
  }, []);

  const productData = useAppSelector(({ products }) => products);
  const { productDetail, loading } = productData;

  const clubs = useAppSelector(clubsSelectors.selectAll);
  const clubsOptions = clubs.map((item) => ({
    value: item.code,
    label: item.name,
  }));

  const handleProductEdit = (values: IProductEditValues) => {
    console.log("values: ", values);
    const filteredClub = clubs.filter((item) =>
      values.clubCodes.includes(item.code)
    );
    if (productDetail && filteredClub) {
      const request: IProductEditRequestArgs = {
        code: productDetail.code,
        active: values.active,
        title: values.title,
        oldPrice: values.oldPrice,
        price: values.price,
        description: values.description,
        promocode: values.promocode,
        clubs: filteredClub,
      };
      void dispatch(editProduct(request));
    }
  };

  if (!param.id || loading === "failed") {
    return <PageNotFound />;
  }

  if (loading === "loading") {
    return <Preloader />;
  }

  return productDetail ? (
    <>
      <PageHeader
        title={`Редактирование акции: ${productDetail.title}`}
        onBack={() => navigate(-1)}
      />
      {productDetail && (
        <Card>
          <ShowErrorMessages />
          <Form
            name="editProduct"
            labelCol={form.LebelColWide}
            wrapperCol={form.WrapperColWide}
            autoComplete="off"
            initialValues={{
              active: productDetail.active,
              title: productDetail.title,
              oldPrice: productDetail.oldPrice,
              price: productDetail.price,
              description: productDetail.description,
              clubCodes: productDetail.clubs.map((club) => club.code),
              promocode: productDetail.promocode,
            }}
            onFinish={handleProductEdit}
          >
            <Form.Item
              label="Активна"
              name="active"
              valuePropName="checked"
              initialValue
            >
              <Switch />
            </Form.Item>

            <Form.Item
              label="Заголовок акции"
              name="title"
              rules={[
                {
                  required: true,
                  message: "Пожалуйста укажите заголовок акции",
                },
              ]}
            >
              <Input />
            </Form.Item>

            <Form.Item label="Старая цена" name="oldPrice">
              <InputNumber precision={2} stringMode />
            </Form.Item>

            <Form.Item
              label="Цена"
              name="price"
              rules={[
                {
                  required: true,
                  message: "Пожалуйста укажите цену",
                },
              ]}
            >
              <InputNumber precision={2} stringMode />
            </Form.Item>

            <Form.Item
              label="Разрешить промокоды"
              name="promocode"
              valuePropName="checked"
            >
              <Checkbox>Да</Checkbox>
            </Form.Item>

            <Form.Item
              label="Клубы"
              name="clubCodes"
              rules={[
                {
                  required: true,
                  message: "Пожалуйста выберите клубы",
                },
              ]}
            >
              <Select options={clubsOptions} mode="multiple" />
            </Form.Item>

            <Form.Item
              label="Описание акции"
              name="description"
              tooltip="Переводы строк преобразуются в галочки"
              rules={[
                {
                  required: true,
                  message: "Пожалуйста укажите описание акции",
                },
              ]}
            >
              <TextArea rows={4} showCount maxLength={150} />
            </Form.Item>

            <Form.Item wrapperCol={form.SubmitCol}>
              <Button type="primary" htmlType="submit">
                Сохранить
              </Button>
            </Form.Item>
          </Form>
        </Card>
      )}
    </>
  ) : null;
}

export default PromocodeEdit;
