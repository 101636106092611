import { SorterResult, SortOrder } from "antd/lib/table/interface";

import { ISorterRusult } from "@/shared/models/slice";

export const getSorterOrder = (
  state: ISorterRusult | ISorterRusult[],
  key: string
): SortOrder | undefined => {
  if (Array.isArray(state)) {
    return state.find((el) => el.field === key)?.order || undefined;
  }
  return state.order || undefined;
};

export const convertSorterConfig = <T extends Record<string, any>>(
  config: SorterResult<T> | SorterResult<T>[]
): ISorterRusult | ISorterRusult[] => {
  if (Array.isArray(config)) {
    return config.map((el) => {
      return {
        field: el.field,
        order: el.order,
        columnKey: el.columnKey,
      };
    });
  }
  return {
    field: config.field,
    order: config.order,
    columnKey: config.columnKey,
  };
};
