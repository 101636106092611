import {
  Button,
  Card,
  DatePicker,
  Form,
  Input,
  PageHeader,
  Upload,
} from "antd";
import dayjs from "dayjs";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

import { ShowErrorMessages, errorActions } from "@/entities/error";
import * as form from "@/constants/formsWrappers";
import { slugRegExp } from "@/constants/params";
import { useAppDispatch } from "@/app";
import { convertIFileResponseToPhotoListItem } from "@/shared/models/files";
import frontendPaths from "@/routes/paths";
import customUpload, { normFile } from "@/shared/utils/customUpload";
import { IOfferEditValues, createOffer } from "@/entities/offers";
import { IOfferCreateArgs } from "@/entities/offers/model/types/offers";

function OfferCreate() {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const [isCreated, setIsCreated] = useState(false);

  useEffect(() => {
    void dispatch(errorActions.resetErrors());
  }, []);

  const handleOfferCreate = (values: IOfferEditValues) => {
    if (!values.photo || !values.photo[0]?.response?.code) return;

    const args: IOfferCreateArgs = {
      name: values.name,
      title: values.title,
      endAt: dayjs(values.endAt).format("YYYY-MM-DD hh:mm:ss"),
      photo: convertIFileResponseToPhotoListItem(values.photo[0].response),
    };
    void dispatch(createOffer(args)).then((res) => {
      if (res.meta.requestStatus === "fulfilled") setIsCreated(true);
    });
  };

  return (
    <>
      <PageHeader
        title={frontendPaths.offer_create.title}
        onBack={() => navigate(-1)}
      />
      <Card>
        <ShowErrorMessages />
        <Form
          name="createOffer"
          labelCol={form.LebelColWide}
          wrapperCol={form.WrapperColWide}
          autoComplete="off"
          onFinish={handleOfferCreate}
          disabled={isCreated}
        >
          <Form.Item
            label="Метка"
            name="name"
            rules={[
              {
                required: true,
                message: "Пожалуйста укажите метку спец-предложения",
              },
              {
                required: true,
                pattern: new RegExp(slugRegExp),
                message: "Пожалуйста метку латинскими буквами",
              },
            ]}
          >
            <Input />
          </Form.Item>

          <Form.Item
            label="Заголовок"
            name="title"
            rules={[
              {
                required: true,
                message: "Пожалуйста укажите заголовок спец-предложения",
              },
            ]}
          >
            <Input />
          </Form.Item>

          <Form.Item
            label="Дата до"
            name="endAt"
            rules={[
              {
                required: true,
                message: "Пожалуйста укажите дату завершения",
              },
            ]}
          >
            <DatePicker showTime />
          </Form.Item>

          <Form.Item
            label="Фото"
            name="photo"
            valuePropName="fileList"
            getValueFromEvent={normFile}
            rules={[
              {
                required: true,
                message: "Пожалуйста укажите фото спец-предложения",
              },
            ]}
          >
            <Upload
              accept="image/*"
              customRequest={customUpload}
              listType="picture-card"
              multiple={false}
              maxCount={1}
            >
              <Button type="link">Загрузить</Button>
            </Upload>
          </Form.Item>

          <Form.Item wrapperCol={form.SubmitCol}>
            <Button type="primary" htmlType="submit">
              Сохранить
            </Button>
          </Form.Item>
        </Form>
      </Card>
    </>
  );
}

export default OfferCreate;
