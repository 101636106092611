import React from "react";
import ReactDOM from "react-dom";

import { AntdConfigProvider, StoreProvider } from "@/app";

import Router from "./routes/Router";

import "./index.css";

// replace console.* for disable log on production
if (process.env.NODE_ENV === "production") {
  console.log = () => {}; // eslint-disable-line no-empty-function
  console.error = () => {}; // eslint-disable-line no-empty-function
  console.debug = () => {}; // eslint-disable-line no-empty-function
}

// remove StrictMode on Production
if (process.env.NODE_ENV === "production") {
  ReactDOM.render(
    <StoreProvider>
      <AntdConfigProvider>
        <Router />
      </AntdConfigProvider>
    </StoreProvider>,
    document.getElementById("root")
  );
} else {
  ReactDOM.render(
    <React.StrictMode>
      <StoreProvider>
        <AntdConfigProvider>
          <Router />
        </AntdConfigProvider>
      </StoreProvider>
    </React.StrictMode>,
    document.getElementById("root")
  );
}

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
// reportWebVitals();
