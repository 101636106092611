import {
  Button,
  Card,
  Checkbox,
  Form,
  Input,
  PageHeader,
  Select,
  Space,
} from "antd";
import dayjs from "dayjs";
import { useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";

import DatePicker from "@/components/DatePicker";
import { ShowErrorMessages, errorActions } from "@/entities/error";
import * as form from "@/constants/formsWrappers";
import { useAppDispatch, useAppSelector } from "@/app";
import frontendPaths from "@/routes/paths";
import getFullName from "@/shared/utils/getFullName";
import {
  IScheduleCreateValues,
  IScheduleEditArgs,
  createEvent,
  editEvent,
  fetchEvent,
} from "@/entities/schedule";
import { coachsSelectors, fetchCoachs } from "@/entities/coachs";
import { fetchAllAreas } from "@/entities/club";
import { fetchProgramms, programmsSelectors } from "@/entities/programms";

import PageNotFound from "./PageNotFound";

const { TextArea } = Input;

function ScheduleEdit() {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const param = useParams();
  const [editEventForm] = Form.useForm();

  useEffect(() => {
    void dispatch(errorActions.resetErrors());
    void dispatch(fetchProgramms());
    void dispatch(fetchCoachs());
    void dispatch(fetchAllAreas());
    param.id && void dispatch(fetchEvent(param.id));
  }, []);

  const scheduleData = useAppSelector(({ schedule }) => schedule);
  const { eventDetail, loading } = scheduleData;

  const programms = useAppSelector(programmsSelectors.selectAll);
  const programmsOptions = programms.map((item) => {
    return {
      value: item.code,
      label: item.name,
    };
  });

  const coachs = useAppSelector(coachsSelectors.selectAll);
  const coachsOptions = coachs.map((item) => {
    return {
      value: item.code,
      label: getFullName(item.firstName, item.lastName),
    };
  });

  const { clubAllAreas } = useAppSelector(({ clubs }) => clubs);
  const areasOptions = clubAllAreas?.map((item) => {
    return {
      value: item.code,
      label: `${item.name} (${item.club.name})`,
    };
  });

  const handleScheduleEdit = (values: IScheduleCreateValues) => {
    const teacher = coachs.find((item) => item.code === values.teacherCode);
    const area = clubAllAreas?.find((item) => item.code === values.areaCode);
    const program = programms.find((item) => item.code === values.programCode);
    if (param.id && teacher && area && program) {
      const request: IScheduleEditArgs = {
        code: param.id,
        startedAt: dayjs(values.startedAt).format("YYYY-MM-DD HH:mm:ss"),
        paid: values.paid,
        comment: values.comment,
        teacher,
        area,
        program,
      };
      console.log(request);
      void dispatch(editEvent(request));
    }
  };

  const handleScheduleSaveAs = () => {
    const values = editEventForm.getFieldsValue();
    const teacher = coachs.find((item) => item.code === values.teacherCode);
    const area = clubAllAreas?.find((item) => item.code === values.areaCode);
    const program = programms.find((item) => item.code === values.programCode);
    if (param.id && teacher && area && program) {
      const request: IScheduleEditArgs = {
        code: param.id,
        startedAt: dayjs(values.startedAt).format("YYYY-MM-DD HH:mm:ss"),
        paid: values.paid,
        comment: values.comment,
        teacher,
        area,
        program,
      };
      void dispatch(createEvent(request));
    }
  };

  if (!param.id) {
    return <PageNotFound />;
  }

  //   if (loading === "loading") {
  //     return <Preloader />;
  //   }

  if (!eventDetail) return null;

  return (
    <>
      <PageHeader
        title={`${frontendPaths.schedule_edit.title}: ${dayjs(
          eventDetail.startedAt
        ).format("YYYY-MM-DD HH:mm")}`}
        onBack={() => navigate(-1)}
      />
      {eventDetail ? (
        <Card>
          <ShowErrorMessages />
          <Form
            name="editEvent"
            form={editEventForm}
            labelCol={form.LebelColWide}
            wrapperCol={form.WrapperColWide}
            autoComplete="off"
            onFinish={handleScheduleEdit}
            disabled={loading === "loading"}
            initialValues={{
              startedAt: dayjs(eventDetail.startedAt, "YYYY-MM-DD HH:mm"),
              programCode: eventDetail.programCode,
              teacherCode: eventDetail.teacherCode,
              areaCode: eventDetail.areaCode,
              paid: eventDetail.paid,
              comment: eventDetail.comment,
            }}
          >
            <Form.Item
              label="Дата и время начала"
              name="startedAt"
              rules={[
                {
                  required: true,
                  message: "Пожалуйста укажите дату и время начала",
                },
              ]}
            >
              <DatePicker
                showTime={{
                  format: "HH:mm",
                }}
                format="YYYY-MM-DD HH:mm"
                minuteStep={5}
              />
            </Form.Item>

            <Form.Item
              label="Программа"
              name="programCode"
              rules={[
                {
                  required: true,
                  message: "Пожалуйста укажите программу",
                },
              ]}
            >
              <Select options={programmsOptions} />
            </Form.Item>

            <Form.Item
              label="Тренер"
              name="teacherCode"
              rules={[
                {
                  required: true,
                  message: "Пожалуйста укажите количество калорий",
                },
              ]}
            >
              <Select options={coachsOptions} />
            </Form.Item>

            <Form.Item
              label="Зал и клуб"
              name="areaCode"
              rules={[
                {
                  required: true,
                  message: "Пожалуйста укажите зал и клуб",
                },
              ]}
            >
              <Select options={areasOptions} />
            </Form.Item>

            <Form.Item
              label="Платное участие"
              name="paid"
              valuePropName="checked"
            >
              <Checkbox>Да</Checkbox>
            </Form.Item>

            <Form.Item label="Комментарии" name="comment">
              <TextArea rows={6} />
            </Form.Item>

            <Form.Item wrapperCol={form.SubmitCol}>
              <Space>
                <Button type="primary" htmlType="submit">
                  Сохранить
                </Button>
                <Button type="default" onClick={handleScheduleSaveAs}>
                  Сохранить как
                </Button>
              </Space>
            </Form.Item>
          </Form>
        </Card>
      ) : null}
    </>
  );
}

export default ScheduleEdit;
