import { Card, Descriptions, PageHeader, Tag } from "antd";
import { useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";

import { ShowErrorMessages, errorActions } from "@/entities/error";
import { useAppDispatch, useAppSelector } from "@/app";
import frontendPaths from "@/routes/paths";
import { fetchformQuerie } from "@/entities/formQueries";
import Preloader from "@/components/Preloader";

function FormQueriesPage() {
  const navigate = useNavigate();
  const param = useParams();
  const dispatch = useAppDispatch();

  useEffect(() => {
    void dispatch(errorActions.resetErrors());
    param.id && dispatch(fetchformQuerie(param.id));
  }, []);

  const formQuerie = useAppSelector(({ formQueries }) => formQueries);
  const { loading, formDetail } = formQuerie;

  if (loading === "loading") return <Preloader />;

  return (
    <>
      <PageHeader
        title={frontendPaths.form_querie.title}
        onBack={() => navigate(-1)}
      />
      <Card>
        <ShowErrorMessages />
        <Descriptions>
          <Descriptions.Item label="createdAt">
            {formDetail?.createdAt}
          </Descriptions.Item>
          <Descriptions.Item label="Имя">{formDetail?.name}</Descriptions.Item>
          <Descriptions.Item label="Телефон">
            {formDetail?.phone}
          </Descriptions.Item>
          <Descriptions.Item label="Клуб">{formDetail?.club}</Descriptions.Item>
          <Descriptions.Item label="Спец-предложение">
            {formDetail?.specialOffer}
          </Descriptions.Item>
          <Descriptions.Item label="Форма">
            <Tag>{formDetail?.type}</Tag>
          </Descriptions.Item>
          <Descriptions.Item label="Пожелания">
            {formDetail?.note}
          </Descriptions.Item>
        </Descriptions>
      </Card>
    </>
  );
}

export default FormQueriesPage;
