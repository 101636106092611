import { Button, Card, Form, Input, PageHeader } from "antd";
import { useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";

import Preloader from "@/components/Preloader";
import { ShowErrorMessages, errorActions } from "@/entities/error";
import * as form from "@/constants/formsWrappers";
import { useAppDispatch, useAppSelector } from "@/app";
import frontendPaths from "@/routes/paths";
import {
  ISliderEditRequest,
  ISliderEditValues,
  editSlider,
  fetchSlider,
} from "@/entities/sliders";

import PageNotFound from "./PageNotFound";

function SliderEdit() {
  const param = useParams();
  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  useEffect(() => {
    void dispatch(errorActions.resetErrors());
    param.id && dispatch(fetchSlider(param.id));
  }, []);

  const sliderData = useAppSelector(({ sliders }) => sliders);
  const { sliderDetail, loading } = sliderData;

  const handleSliderEdit = (values: ISliderEditValues) => {
    if (sliderDetail) {
      const request: ISliderEditRequest = {
        code: sliderDetail.code,
        ...values,
      };
      void dispatch(editSlider(request));
    }
  };

  if (!param.id || loading === "failed") {
    return <PageNotFound />;
  }

  if (loading === "loading") {
    return <Preloader />;
  }

  return sliderDetail ? (
    <>
      <PageHeader
        title={`${frontendPaths.slider_edit.title}: ${sliderDetail.title}`}
        onBack={() => navigate(-1)}
      />
      <Card>
        <ShowErrorMessages />
        <Form
          name="editSlider"
          labelCol={form.LebelColWide}
          wrapperCol={form.WrapperColWide}
          autoComplete="off"
          onFinish={handleSliderEdit}
          initialValues={{
            title: sliderDetail.title,
            subTitle: sliderDetail.subTitle,
            buttonTitle: sliderDetail.buttonTitle,
            buttonLink: sliderDetail.buttonLink,
          }}
        >
          <Form.Item
            label="Заголовок"
            name="title"
            rules={[
              {
                required: true,
                message: "Пожалуйста укажите заголовок слайдера",
              },
            ]}
          >
            <Input />
          </Form.Item>

          <Form.Item
            label="Подзаголовок"
            name="subTitle"
            rules={[
              {
                required: true,
                message: "Пожалуйста укажите подзаголовок слайдера",
              },
            ]}
          >
            <Input />
          </Form.Item>

          <Form.Item
            label="Название кнопки"
            name="buttonTitle"
            rules={[
              {
                required: true,
                message: "Пожалуйста укажите название кнопки",
              },
            ]}
          >
            <Input />
          </Form.Item>

          <Form.Item
            label="URL кнопки"
            extra="Для формы 'отправить заявку' укажите #send-request"
            name="buttonLink"
            rules={[
              {
                required: true,
                message: "Пожалуйста укажите URL кнопки",
              },
            ]}
          >
            <Input />
          </Form.Item>

          <Form.Item wrapperCol={form.SubmitCol}>
            <Button type="primary" htmlType="submit">
              Сохранить
            </Button>
          </Form.Item>
        </Form>
      </Card>
    </>
  ) : null;
}

export default SliderEdit;
