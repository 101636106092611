import { ColumnFilterItem } from "antd/lib/table/interface";

interface IFilteredEntitie {
  name: string;
  code: string;
}

function mapFilter<T extends IFilteredEntitie>(
  entities: T[]
): ColumnFilterItem[] {
  return entities.map((obj) => {
    return {
      text: obj.name,
      value: obj.code,
    };
  });
}

export default mapFilter;
